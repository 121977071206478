import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";
import { AppRoute } from "../../app-route.model";
import { MenuKey } from "../dashboard/menu/menu-key.enum";

@Injectable({ providedIn: "root" })
export class BreadcrumbService {

  private breadcrumbItemSubject$ = new Subject<MenuKey>();
  private breadcrumbItems: AppRoute[] = [];

  breadcrumbChanges$ = new BehaviorSubject<AppRoute[]>([]);
  breadcrumbItemChange$ = this.breadcrumbItemSubject$.asObservable();

  constructor() {}

  setBreadcrumb(breadcrumbItems: AppRoute[]) {
    this.breadcrumbItems = breadcrumbItems;
    this.breadcrumbChanges$.next([... this.breadcrumbItems]);
  }

  clear() {
    this.breadcrumbItems = [];
    this.breadcrumbChanges$.next([... this.breadcrumbItems]);
  }

  emitBreadcrumbItemChange(menuKey: MenuKey) {
    this.breadcrumbItemSubject$.next(menuKey);
  }
}
