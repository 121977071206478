import { MsalGuardConfiguration } from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { environment } from "../../../environments/environment";
import { b2cPolicies } from "./msal-utility";

export class B2CAuthConfig {
  public static get msalConfig(): Configuration {

    return {
      auth: {
        clientId: environment.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: environment.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: environment.redirectUri, // Indicates the page to navigate after logout.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            console.log(message);
          },
          piiLoggingEnabled: false,
          logLevel: LogLevel.Verbose,
        },
      },
    };
  }
}
