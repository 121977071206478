import * as Search from "../../../../../data/framework/searchObjects";
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ControllerWithCommandServiceBase } from "../../../controller-with-command-service-base"
import { PageContainerService } from "../../../../../services/page-container.service"
import { DateTime } from 'luxon';
import { ILink } from "../../../../../data/framework/Ilink"
import * as _ from 'lodash';
import { ActivatedRoute, Location, Params, Subject } from "../../../../../ng";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { ICellLink, ISearchGridFormat } from "../../../../../data";
import { ICellLinkQueryParam } from "../../../../../data/framework/IcellLink";
import { SpkTextComponent } from "../../inputs/spk-text/spk-text.component";
import { IOnClickCellAction } from "../../../../../data/framework/IonClickCellAction";
import { UserFileService } from "../../../../../services";
import { CommandNames, UserFileType } from "../../../../../data/CommandServiceDtos.generated";
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'spk-search-grid',
  templateUrl: './spk-search-grid.component.html',
  styleUrls: ['./spk-search-grid.component.scss']
})

export class SpkSearchGridComponent extends ControllerWithCommandServiceBase implements OnInit, OnChanges, AfterViewInit {


  @Input() public showSearch: boolean = true;
  @Input() public autoload: boolean = true;
  @Input() public list: string;
  @Input() public leftLinks: ILink[] = [];
  @Input() public rightLinks: ILink[] = [];
  @Input() public headerLinks: ILink[] = [];
  @Input() public subscribeToSearchGridService: boolean = true;
  @Input() public filter: Search.FilterSpecification;
  @Input() public defaultSortProperty: string;
  @Input() public filterSpecificationProviderString: string = '';
  @Input() public cellLinks: ICellLink[] = [];
  @Input() public onClickCellActions: IOnClickCellAction[] = [];
  @Input() public csvExport: boolean = false;
  @Input() public pdfExport: boolean = false;
  @Input() public cardView: boolean = false;
  @Input() public title: string;
  @Input() public useQueryString: boolean = true;
  @Input() public command: string = null;
  @Input() public usesFilter: boolean = false;
  @Input() focusSearch: boolean;

  @Input() public mousedOverRow: any;
  @Input() public columnFormats: ISearchGridFormat[] = [];
  @Input() public rowFormats: ISearchGridFormat[] = [];
  @Input() customFormats: any[] = [];
  @Output() public mousedOverRowChange: EventEmitter<any> = new EventEmitter<any>();

  public searchTermHasBeenSearched: boolean = false;

  @ViewChild('searchInput', { static: false }) public searchInput: SpkTextComponent;

  csvButtonDisabled: boolean = false;
  pdfButtonDisabled: boolean = false;

  private _searchString = '';
  public get searchString(): string {
    return this._searchString;
  }
  @Input() public set searchString(value) {
    if (value != null && value != undefined)
      value = value.toUpperCase();
    this._searchString = value;
    this.searchTermHasBeenSearched = false;
    this.searchStringChange.emit(value);
  }
  @Output() public searchStringChange = new EventEmitter<string>();

  public columns: any[] = [];
  public pagingInfo: Search.PaginationSpecification = { PageIndex: 1, PageSize: 10 };
  public sortingInfo = { SortString: '' };
  public rows: any[] = [];
  private sortField: string;
  private sortDirection: string;
  private oldSearchString: string;
  private oldFilter: Search.FilterSpecification;
  public csvUrl: string = "";
  public pdfUrl: string = "";
  private pageSizeQueryParam: string = 'pageSize';
  private pageIndexQueryParam: string = 'pageIndex';
  private searchTextQueryParam: string = 'searchText';
  private sortStringQueryParam: string = 'sortString';
  private previousPageSize: number;
  private previousPageIndex: number;
  private previousSearchText: string;
  private previousSortString: string;
  private queryParamsLoaded: boolean = false;
  hasLoaded: boolean;
  onChanges = new Subject<SimpleChanges>();

  public pagedResult: Search.IPagedResult<any> = <Search.IPagedResult<any>>{
    PaginationInfo: this.pagingInfo, TotalCount: 0, Data: []
  };

  constructor(
    public pcs: PageContainerService,
    public http: HttpClient,
    public location: Location,
    public activatedRoute: ActivatedRoute,
    private percentPipe: PercentPipe,
    public userFileService: UserFileService
  ) {
    super(pcs);
  }
  async ngOnInit() {
    if (this.subscribeToSearchGridService)
      this.pcs.searchGridService.subscribe(this);
    this.onChanges.subscribe((changes: SimpleChanges) => {
      for (let propName in changes) {
        if (propName == 'filter') {
          if (changes[propName].currentValue) {
            this.queryParamsLoaded = true;
            this.handleQueryParams(this.activatedRoute.queryParams);
          }
        }
      }
    });
    if (this.useQueryString) {
      this.activatedRoute.queryParams.subscribe(async (queryParams: Params) => {
        //this if statement "seems" redundant because it's also in the handleQueryParams() function, 
        //but this prevents it from entering the handle if they didn't change.
        //handle also autoloads if autoload is true.  so it prevents it from autoloading by having the check here.  
        //this is a subscription to the params so everytime any queryparams change (even if they aren't related to search, like tabs for example) this would trigger
        if (this.queryParamsExistAndHaveChanged(queryParams)) {
          this.queryParamsLoaded = true;
          if (!this.usesFilter) {
            this.handleQueryParams(queryParams);
          }
        }
      });
    }
    if (!this.queryParamsLoaded) {
      if (!this.usesFilter) {
        this.queryParamsLoaded = true;
        this.handleQueryParams(this.activatedRoute.queryParams);
      }
    }
  }

  keyDown(value) {
    if (value.keyCode == 13) {
      this.load();
      return false;
    }
    return true;
  }

  ngAfterViewInit(): void {
    if (this.showSearch)
      this.searchInput.input.nativeElement.onkeydown = (evt) => this.keyDown(evt);
    if (this.focusSearch)
      this.searchInput.input.nativeElement.focus();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
    if (this.csvExport) {
      this.setCsvUrl();
    }
  }

  private handleQueryParams(queryParams: Params = null) {
    if (this.useQueryString && queryParams && this.queryParamsExistAndHaveChanged(queryParams)) {
      if (queryParams[this.pageSizeQueryParam] && queryParams[this.pageIndexQueryParam]) {
        this.pagingInfo.PageIndex = +queryParams[this.pageIndexQueryParam];
        this.pagingInfo.PageSize = +queryParams[this.pageSizeQueryParam];
      }
      else {
        this.pagingInfo.PageIndex = 1;
        this.pagingInfo.PageSize = 10;
      }
      if (queryParams[this.searchTextQueryParam] && this.showSearch) {
        this.searchString = queryParams[this.searchTextQueryParam];
      }
      else {
        this.searchString = "";
      }
      if (queryParams[this.sortStringQueryParam] && queryParams[this.sortStringQueryParam] != "undefined") {
        this.parseSortString(queryParams[this.sortStringQueryParam]);
      }
      else if (this.defaultSortProperty != null) {
        this.parseSortString(this.defaultSortProperty);
      }
    }
    else {
      //this.parseSortString(this.defaultSortProperty);
    }
    if (this.isSetToAutoload() || (!this.isSetToAutoload() && this.queryParamsExistAndHaveChanged(queryParams))) {
      this.load();
    }
  }

  isSetToAutoload(): boolean {
    return (this.autoload && (this.autoload === true || this.autoload === "true")) || this.hasLoaded;
  }

  queryParamsExistAndHaveChanged(queryParams: Params): boolean {
    if (queryParams &&
      (queryParams[this.pageSizeQueryParam] || queryParams[this.pageIndexQueryParam]
        || queryParams[this.searchTextQueryParam] || queryParams[this.sortStringQueryParam])
      && (queryParams[this.pageSizeQueryParam] != this.previousPageSize || queryParams[this.pageIndexQueryParam] != this.previousPageIndex
        || queryParams[this.searchTextQueryParam] != this.previousSearchText || queryParams[this.sortStringQueryParam] != this.previousSortString)
    ) {
      return true;
    }
    return false;
  }

  setSearchQueryString() {
    let searchParams: any = {
      pageIndex: this.pagingInfo.PageIndex,
      pageSize: this.pagingInfo.PageSize,
      sortString: this.sortingInfo.SortString
    };
    if (this.showSearch) {
      let searchStringObject = {
        searchText: this.searchString
      };
      searchParams = { ...searchStringObject, ...searchParams };
    }
    this.previousPageIndex = searchParams[this.pageIndexQueryParam];
    this.previousPageSize = searchParams[this.pageSizeQueryParam];
    this.previousSearchText = searchParams[this.searchTextQueryParam];
    this.previousSortString = searchParams[this.sortStringQueryParam];
    this.pcs.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          ...searchParams
        },
        queryParamsHandling: "merge",
        replaceUrl: true
      }
    );
  }

  public async load(): Promise<any> {
    if (this.oldSearchString != this.searchString || this.filtersHaveChanged()) {
      this.pagingInfo.PageIndex = 1;
    }
    this.oldFilter = this.filter;
    this.oldSearchString = this.searchString;

    var payload = this.getCommandPayload();
    var commandName = this.command != null && this.command != "" ? this.command : "Search";
    var result = await this.pcs.executeCommandService.executeCommand(commandName, payload);
    await this.loadScreen(result);
    return result;
  }

  filtersHaveChanged(): boolean {
    return JSON.stringify(this.oldFilter) !== JSON.stringify(this.filter);
  }

  private getCommandPayload(): any {
    var payload = {};
    var actualFilter = this.filter == null ? new Search.FilterSpecification() : this.filter;
    payload = {
      SearchType: this.list,
      SearchCriteria: {
        SearchText: this.searchString,
        PaginationSpecification: this.pagingInfo,
        SortSpecification: this.sortingInfo,
        FilterSpecification: actualFilter
      },
      UserFileId: 0
    };
    return payload;
  }

  public loadScreen = (data) => {
    if (data.Succeeded) {
      this.hasLoaded = true;
      var payload = data.Payload;
      this.pagedResult = payload.PagedResult;
      this.columns = payload.Columns;
      this.pagingInfo = this.pagedResult.PaginationInfo;
      this.rows = this.pagedResult.Data;
      this.searchTermHasBeenSearched = true;
      if (this.useQueryString) {
        this.setSearchQueryString();
      }
      if (this.csvExport) {
        this.setCsvUrl();
      } if (this.pdfExport) {
        this.setPdfUrl();
      }
    }
    else {
      this.pcs.notificationService.danger(data.Message);
    }
  }

  getCustomFormat(columnName: string, rowColumnValue: any): string {
    return this.customFormats[columnName](rowColumnValue);
  }

  getHeaderAlignment(column: any) {
    column.Format
  }

  setCsvUrl() {
    var payload = this.getCommandPayload();
    payload.CommandName = this.command != null && this.command != "" ? this.command : "Search";
    var jsonPayload = JSON.stringify(payload);
    jsonPayload = encodeURIComponent(jsonPayload);
    this.csvUrl = `SearchCsvExport?request=${jsonPayload}`;
  }

  setPdfUrl() {
    var payload = this.getCommandPayload();
    payload.CommandName = this.command != null && this.command != "" ? this.command : "Search";
    var jsonPayload = JSON.stringify(payload);
    jsonPayload = encodeURIComponent(jsonPayload);
    this.pdfUrl = `SearchPdfExport?request=${jsonPayload}`;
  }

  async generatePdf() {
    var commandRequest = this.getCommandPayload();
    commandRequest.CommandName = this.command != null && this.command != "" ? this.command : "Search";
    await this.userFileService.createRequestAndQueueFileCommand(CommandNames.PdfSearch, commandRequest, UserFileType.SearchPdf);
    var that = this;
    this.pdfButtonDisabled = true;
    setTimeout(() => {
      that.pdfButtonDisabled = false;
    }, 10000);
  }

  public parseSortString(propertyName: string): boolean {
    if (propertyName) {
      if (propertyName.endsWith(' ASC') || propertyName.endsWith(' DESC')) {
        let split = propertyName.split(' ');
        this.sortField = split[0];
        this.sortDirection = split[1];
        this.sortingInfo.SortString = propertyName;
        return true;
      }

      this.sortField = propertyName;

      if (this.sortField != null) {
        this.sortingInfo.SortString = this.sortField;

        if (!_.isUndefined(this.sortDirection)) {
          this.sortingInfo.SortString += ' ' + this.sortDirection;
        }

      }

      return true;
    }
    return false;
  }
  public sortBy(propertyName: string) {
    var reverse = (this.sortField != null && this.sortField == propertyName);
    if (reverse && this.sortDirection != null && this.sortDirection == "ASC")
      this.sortDirection = "DESC";
    else
      this.sortDirection = "ASC";

    if (this.parseSortString(propertyName))
      this.load();
  }
  public format(value: string, format: string) {
    if (value == null)
      return '';
    try {
      switch (format) {
        case "Text":
          return value;
        case "UtcDate":
          var date = new Date(value);
          var day = date.getUTCDate();
          var month = date.getUTCMonth() + 1;
          var year = date.getUTCFullYear();
          return `${month}/${day}/${year}`;
        case "Date":
          var date = DateTime.fromISO(value).toJSDate();
          return date.toLocaleDateString("en-US");
        case "Time":
          var date = new Date(value);
          return date.toTimeString();
        case "DateTime":
          //var date = new Date(value);
          var date = DateTime.fromISO(value).toJSDate();
          return date.toLocaleString();
        case "Currency":
          var currencyPipe = new CurrencyPipe('en-US');
          var currency = currencyPipe.transform(value, 'USD', "symbol", '1.2');
          return currency;
        case "Percent":
          var result = this.percentPipe.transform(value, '1.2-2');
          return result;
        case "PercentThreeDecimal":
          var result = this.percentPipe.transform(value, '1.3-3');
          return result;
        case "Decimal":
          var number = parseFloat(value);
          return number.toFixed(3);
        case "PhoneNumber":
          if (value.length == 10) {
            var first = value.substr(0, 3);
            var second = value.substr(3, 3);
            var third = value.substr(6, 4);
            return `(${first}) ${second}-${third}`;
          }
          else {
            return value;
          }
        default:
          return value;
      }
    }
    catch (e) {
      return value;
    }
  }

  shouldUseReallyClick(link: ILink) {
    var hasAction = link.reallyClickAction != null;
    var hasMessage = link.reallyClickMessage != null;

    return hasMessage || hasAction;
  }

  cellIsStringFormat(format: string): boolean {
    switch (format) {
      case "NavigationLink":
      case "OnTextClick":
      case "CheckXBoolean":
      case "CheckNothingBoolean":
      case "CheckBox":
      case "Image":
      case "Custom":
        return false;
      default:
        return true;
    }
  }

  getcsv() {
    this.pcs.validationResultsService.reset();
    this.pcs.notificationService.removePersistents();
    if (this.oldSearchString == null) {
      this.oldSearchString = this.searchString;
    }
    else if (this.oldSearchString != this.searchString) {
      this.pagingInfo.PageIndex = 1;
      this.oldSearchString = this.searchString;
    }

    var payload = this.getCommandPayload();
    payload.CommandName = this.command != null && this.command != "" ? this.command : "Search";
    var jsonPayload = JSON.stringify(payload);
    jsonPayload = encodeURIComponent(jsonPayload);
    var promise = this.http.get(`SearchCsvExport?request=${jsonPayload}`, { responseType: 'blob' }).toPromise<any>();
    promise.then((response) => {
      //let blob = response.blob();
      let blobUrl = window.URL.createObjectURL(response);
      var link = document.getElementById("downloadLink");
      var date = (new Date()).toLocaleString();
      link.setAttribute("href", blobUrl);
      link.setAttribute("download", `${this.list}-${date}.csv`);
      link.click();
    });
    this.pcs.notificationService.success("CSV Download Queued");
    var that = this;
    this.csvButtonDisabled = true;
    setTimeout(() => {
      that.csvButtonDisabled = false;
    }, 10000);
  }

  getpdf() {
    this.pcs.validationResultsService.reset();
    this.pcs.notificationService.removePersistents();
    if (this.oldSearchString == null) {
      this.oldSearchString = this.searchString;
    }
    else if (this.oldSearchString != this.searchString) {
      this.pagingInfo.PageIndex = 1;
      this.oldSearchString = this.searchString;
    }

    var payload = this.getCommandPayload();
    var jsonPayload = JSON.stringify(payload);
    jsonPayload = encodeURIComponent(jsonPayload);
    var promise = this.http.get(`SearchPdfExport?request=${jsonPayload}`, { responseType: 'blob' }).toPromise<any>();
    promise.then((response) => {
      //let blob = response.blob();
      let blobUrl = window.URL.createObjectURL(response);
      var link = document.getElementById("downloadLink");
      var date = (new Date()).toLocaleString();
      link.setAttribute("href", blobUrl);
      link.setAttribute("download", `${this.list}-${date}.pdf`);
      link.click();
    });
    this.pcs.notificationService.success("PDF Download Queued");
    var that = this;
    this.pdfButtonDisabled = true;
    setTimeout(() => {
      that.pdfButtonDisabled = false;
    }, 10000);
  }

  getColumnRouterLink(row: any, columnName: string): any[] {
    var result = [];
    var cellLink = _.find(this.cellLinks, (value) => {
      return value.columnName == columnName;
    });
    if (cellLink) {
      result = this.formatLinkHref(row, cellLink);
    }
    return result;
  }

  formatLinkHref(row: any, cellLink: ICellLink): any[] {
    var result: any[] = []
    var tokens = cellLink.route.split("/:");
    result.push(`/${tokens.shift()}`);
    for (var property in row) {
      var lowerCaseProperty = property.toLowerCase();
      if (_.some(tokens, (value: string) => { return value.toLowerCase() == lowerCaseProperty })) {
        result.push(row[property]);
      }
    }
    return result;
  }

  getColumnQueryParams(row: any, columnName: string): any {
    var result: any;
    var cellLink = _.find(this.cellLinks, (value) => {
      return value.columnName == columnName;
    });
    if (cellLink) {
      result = this.formatQueryParams(row, cellLink);
    }
    return result;
  }

  formatQueryParams(row: any, cellLink: ICellLink): any {
    var result: any = {};
    if (cellLink.queryParams != null && cellLink.queryParams != undefined && cellLink.queryParams.length > 0) {
      _.forEach(cellLink.queryParams, (parameter: ICellLinkQueryParam) => {
        if (row[parameter.columnName] != null && row[parameter.columnName] != undefined) {
          result[parameter.name] = row[parameter.columnName];
        }
      });
    }
    return result;
  }

  setMousedOverRow(row: any) {
    this.mousedOverRow = row;
    this.mousedOverRowChange.emit(row);
  }

  unsetMousedOverRow() {
    this.mousedOverRow = null;
    this.mousedOverRowChange.emit(null);
  }

  rowCss(index: number): string {
    var result = "";
    _.forEach(this.rowFormats, (value: ISearchGridFormat) => {
      if (index == value.index) {
        result += `${value.cssClass}`;
      }
    });
    return result;
  }

  columnCss(row: any): string {
    var result = "";
    _.forEach(this.columnFormats, (value: ISearchGridFormat) => {
      if (row[value.columnName] == value.value) {
        result += `${value.cssClass}`;
      }
    });
    return result;
  }

  callClickAction(columnName: string, row: any) {
    let action: IOnClickCellAction = _.find(this.onClickCellActions, (action: IOnClickCellAction) => {
      return action.columnName == columnName;
    });
    if (action != null) {
      action.action.apply(action.caller, [row]);
    }
  }

  async pageDown() {
    if (this.pagedResult.PaginationInfo.PageIndex < (this.pagedResult.TotalCount / this.pagedResult.PaginationInfo.PageSize)) {
      this.pagedResult.PaginationInfo.PageIndex++;
      await this.load();
    }
  }

  async pageUp() {
    if (this.pagedResult.PaginationInfo.PageIndex > 1) {
      this.pagedResult.PaginationInfo.PageIndex--;
      await this.load();
    }
  }
}
