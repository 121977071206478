import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-login-failed",
  templateUrl: "./login-failed.component.html",
  styleUrls: ["./login-failed.component.scss"],
})
export class LoginFailedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
