import { PageContainerService } from '../../services';
import { ControllerWithCommandServiceBase } from './controller-with-command-service-base';
import * as $ from 'jquery';
import { OnInit, Directive } from "@angular/core";
import { Subject } from 'rxjs';

@Directive()
export class ControllerForViewBase extends ControllerWithCommandServiceBase implements OnInit {

  protected hideAll: boolean;
  urlSubscriptionSubject = new Subject();
  urlSubscriptionFunction: Function;

  constructor(
    protected pcs: PageContainerService
  ) {
    super(pcs);
    this.pcs.actionLinksPanelService.reset();
    this.hideAll = true;
  }

  ngOnInit() {
    this.hideAll = false;
  }

  protected clientSecurityFailure() {
    this.pcs.notificationService.removePersistents();
    this.pcs.notificationService.danger('You do not have permission to access this resource');
    this.hideAll = true;
    this.pcs.actionLinksPanelService.reset();
  }
  protected showModal(stringSelector: string) {
    this.changeModalVisibility(stringSelector, 'show');
  }

  protected hideModal(stringSelector: string = '.modal') {
    this.changeModalVisibility(stringSelector, 'hide');
  }

  private changeModalVisibility(stringSelector: string, visibilityState: string) {
    $(stringSelector).modal(visibilityState);
  }

  public back() {
    window.history.back();
  }

  protected async updateUrlForNewEntity(route: any[], queryParams: any = {}) {
    let hasSubscribers = this.urlSubscriptionSubject.observers.length;
    if (hasSubscribers)
      this.urlSubscriptionSubject.observers.pop();
    await this.pcs.router.navigate(
      route,
      {
        queryParams: queryParams,
        queryParamsHandling: "merge",
        replaceUrl: true
      });
    if (hasSubscribers && this.urlSubscriptionFunction)
      this.urlSubscriptionFunction();
  }
}
