import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { 
  AdminGetFeedbackSurveyLinkCommandRequest, 
  AdminSaveFeedbackSurveyLinkCommandRequest, 
  AdminSaveFrequentQuestionListCommandRequest, 
  FeedbackSurveyInfoDto, FrequentQuestionDto, 
  GetFrequentlyAskedQuestionListCommandRequest,
} from '../../data/CommandServiceDtos.generated';
import { PageContainerService } from '../../services';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../translate/language.model';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { AppRoutes } from '../../app-route.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild('deleteFaqConfirmationModal', { static: true }) deleteFaqConfirmationModal: ElementRef;

  faqList: any[] = [];
  feedbackSurveyInfo: FeedbackSurveyInfoDto = {} as FeedbackSurveyInfoDto;
  newMode: boolean = false;
  editMode: boolean = false;
  allowFaqAdminEdit: boolean = true;
  effectiveDate: string;

  currentLanguage = Languages.English;
  faqToDelete: any;

  constructor(
    private pcs: PageContainerService,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb([
      AppRoutes.ADMIN
    ]);

    this.getAdminGetFeedbackSurveyLink();
    this.currentLanguage = Languages.getByCode(this.translate.currentLang);
    this.getFAQ();

    this.translate.onLangChange.subscribe(data => {
      this.currentLanguage = Languages.getByCode(data.lang);
      if (this.currentLanguage?.code === 'en') {
        this.allowFaqAdminEdit = true;
        
      } else {
        this.allowFaqAdminEdit = false;
      }
      this.getFAQ();
    });

    console.log("Current selected language is " + this.currentLanguage?.name);
    console.log("Allow Edit? " + this.allowFaqAdminEdit);
  }

  ngOnDestroy() {
    this.breadcrumbService.clear();
  }

  async getAdminGetFeedbackSurveyLink(): Promise<any> {
    const payload: AdminGetFeedbackSurveyLinkCommandRequest = {};
    await this.pcs.apiService.AdminGetFeedbackSurveyLink(payload).then(response => {
      this.feedbackSurveyInfo = response.FeedbackSurveyInfo;
      const date = new Date(this.feedbackSurveyInfo.EffectiveDate).toISOString()?.split('T')[0];
      this.effectiveDate = date;
    });
  }

  async saveFeedbackSurveyLink(): Promise<any> {
    const payload: AdminSaveFeedbackSurveyLinkCommandRequest = new AdminSaveFeedbackSurveyLinkCommandRequest();
    payload.FeedbakSurveyInfo = {
      EffectiveDate: new Date(this.effectiveDate),
      FeedbackSurveyLinkURL: this.feedbackSurveyInfo.FeedbackSurveyLinkURL,
    }
    await this.pcs.apiService.AdminSaveFeedbackSurveyLink(payload).then(response => {
      console.log('AdminSaveFeedbackSurveyLink: ', response);
    });
  }

  async getFAQ(): Promise<any> {
    this.newMode = false;
    this.editMode = false;

    const payload: GetFrequentlyAskedQuestionListCommandRequest = { Language: this.currentLanguage?.name };
    await this.pcs.apiService.GetFrequentlyAskedQuestionList(payload).then(response => {
      this.faqList = response.FrequentlyAskedQuestions.map((faq) => ({
        ...faq,
        editing: false
      }));
    });
  }

  public setDate(ValidFrom): string {
    return moment(ValidFrom).format('MM/DD/YYYY');
  }

  public setEditMode(faq: any) {
    faq.editing = !faq.editing;
    this.editMode = faq.editing;
  }

  async deleteFAQ() {
    this.faqToDelete.editing = false;
    this.editMode = false;

    this.faqList = this.faqList.map((f) => {
      if (f.Id === this.faqToDelete.Id) {
        f.Active = false;
      }
      return f
    });

    await this.saveFAQ(this.faqToDelete);
    this.faqToDelete = null;
    this.modalService.dismissAll();
  }

  public addNewFAQ() {
    this.newMode = true;
    const newFAQ: FrequentQuestionDto = new FrequentQuestionDto();

    this.faqList = [
      {
        editing: true,
        ...newFAQ
      },
      ...this.faqList
    ];
  }

  public async saveFAQ(faq: any): Promise<any> {
    faq.editing = false;

    this.faqList.forEach((faq) => {
      delete faq.editing;
    });

    const payload: AdminSaveFrequentQuestionListCommandRequest = {
      FrequentlyAskedQuestionList: [
        ...this.faqList
      ]
    };

    await this.pcs.apiService.AdminSaveFrequentQuestionList(payload).then(response => {
      this.getFAQ();
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openDeleteFaqConfirmationModal(faq: FrequentQuestionDto) {
    this.faqToDelete = faq;
    this.modalService
      .open(this.deleteFaqConfirmationModal, { backdrop: 'static', ariaLabelledBy: 'modal-basic-title', animation: false });
  }
}
