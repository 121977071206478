import { Injectable } from '@angular/core';
import { QueueUserFileCommandRequest, UserFileType } from '../data/CommandServiceDtos.generated';
import { PageContainerService } from './page-container.service';
import * as _ from 'lodash';

@Injectable()
export class UserFileService {    

  constructor(public pcs: PageContainerService) { }

  private pendingUserFileIds: number[] = [];

  public async createRequestAndQueueFileCommand(commandName: string, commandRequest: any, userFileType: UserFileType) {
    let request: QueueUserFileCommandRequest = {
      FileCommandName: commandName,
      FileCommandPayloadAsJson: JSON.stringify(commandRequest),
      UserFileType: userFileType
    }
    await this.queueFileCommand(request);
  }

  public async queueFileCommand(request: QueueUserFileCommandRequest) {
    let response = await this.pcs.apiService.QueueUserFile(request, true, false);
    this.addPendingUserFileId(response.UserFileId);
    this.pcs.notificationService.success("File Queued");
  }

  public shouldPollForPendingFiles(): boolean {
    var result = this.pendingUserFileIds.length > 0;
    return result;
  }

  public addPendingUserFileId(userFileId: number) {
    let pendingUserFileIdIndex = this.getPendingUserFileIdIndex(userFileId);
    if (pendingUserFileIdIndex == -1) {
      this.pendingUserFileIds.push(userFileId);
    }
  }

  public addPendingUserFileIds(userFileIds: number[]) {
    _.forEach(userFileIds, (userFileId: number) => {
      this.addPendingUserFileId(userFileId);
    });
  }

  public removeUserFileId(userFileId: number) {
    var pendingUserFileIdIndex = this.getPendingUserFileIdIndex(userFileId);
    if (pendingUserFileIdIndex > -1) {
      this.pendingUserFileIds.splice(pendingUserFileIdIndex, 1);
    }
  }

  private getPendingUserFileIdIndex(userFileId: number) {
    let result = _.findIndex(this.pendingUserFileIds, (value: number) => { return value == userFileId });
    return result;
  }
}
