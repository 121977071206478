import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { ApiService } from '../data/ApiService.generated';
import { ClientSessionService } from './client-session.service';
import { LoginCommandRequest } from "../data/CommandServiceDtos.generated";

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: MsalService,
    private clientSessionService: ClientSessionService,
    private apiService: ApiService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const b2cActiveAccount = this.authService.instance.getActiveAccount();
    if (this.isUserLoggedIn()) {
      return Promise.resolve(true);
    } else if (b2cActiveAccount != null) {

      const accessTokenRequest = {
        scopes: ["openid"],
        account: b2cActiveAccount
      };

      return new Promise<boolean>((resolve, reject) => {
        this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
          authenticationResult => {
            const LoginCommandRequest = {
              Username: authenticationResult.account.username,
              ServiceToken: authenticationResult.idToken,
            }

            this.apiService
              .Login(LoginCommandRequest as LoginCommandRequest)
              .then((loginCommandResponse) => {
                if (loginCommandResponse.IsAuthenticated) {
                  this.clientSessionService.loginUser(loginCommandResponse.UserInfo);
                  return resolve(true);
                } else {
                  this.router.navigate(['login']);
                  return resolve(false);
                }
              });
          },
          error => {
            window.localStorage.clear();
            this.router.navigate(['login']);
          });
      });
    } else {
      this.router.navigate(['login']);
      return Promise.resolve(false);
    }
  }

  isUserLoggedIn(): boolean {
    return this.clientSessionService.loggedIn;
  }
}
