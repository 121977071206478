import { SearchCriteria, ColumnInformation, PaginationSpecification, FilterSpecification } from './framework/searchObjects';

export interface IPagedResult
                {
                    PaginationInfo: PaginationSpecification;
                    TotalCount: number;
                    Data: any[];
                }
	export class AcceleratorDocumentDto {
		Id: number;
		Name: string;
		Description: string;
		FileExtension: string;
		ClassificationId: number;
		ClassificationDisplay: string;
		Classifications: string[] = [];
		UserFavorite: boolean;
		LastUpdated: Date;
		UpdatedSinceLastUserLogin: boolean;
		EnglishVersionURL: string;
		SpanishVersionURL: string;
	}

	export class AddressDto {
		Id: number;
		Address1: string;
		Address2: string;
		Address3: string;
		City: string;
		StateId: number;
		Zip: string;
		CountryId: number;
		IsInternational: boolean;
		StatesList: ValueTextDto[] = [];
		CountriesList: CountryValueTextDto[] = [];
	}

	export class AgeGroupDto {
		Id: number;
		Name: string;
		IsActive: boolean = true;
	}

	export class AgeGroupSearchRow {
		Id: number;
		Name: string;
	}

	export class ApplicationSettingSearchRow {
		Id: number;
		Name: string;
	}

	export class AttendanceSheetDto {
		Id: number;
		ClassId: number;
		Class: ClassDto = new ClassDto();
		ClassDate: DateDto = new DateDto();
		StatusId: number;
		IsActive: boolean = true;
		AttendanceSheetStatusList: ValueTextDto[] = [];
		AttendanceSheetEntries: AttendanceSheetEntryDto[] = [];
	}

	export class AttendanceSheetEntryDto {
		Id: number;
		AttendanceSheetId: number;
		ChildId: number;
		StatusId: number;
		IsActive: boolean = true;
		IsDeleted: boolean;
		CreatedBy: number;
		CreatedDate: Date;
		AttendanceSheet: AttendanceSheetDto = new AttendanceSheetDto();
		Child: ChildDto = new ChildDto();
		AttendanceSheetEntryStatusList: ValueTextDto[] = [];
	}

	export class AttendanceSheetSearchRow {
		Id: number;
		ClassDate: Date;
		ClassDay: string;
		Status: string;
		Teacher: string;
		CenterName: string;
		ClassName: string;
		PresentCount: number;
		TardyCount: number;
		AbsentCount: number;
		IsActive: boolean = true;
		ClassId: number;
		CenterPrimaryContactId: number;
		CenterSecondaryContactId: number;
		CenterSeniorMentorId: number;
	}

	export class AttendanceSheetStatusDto {
		Id: number;
		Name: string;
	}

	export class CenterDto {
		Id: number;
		Name: string;
		Phone: string;
		Email: string;
		License: number;
		IsActive: boolean = true;
		YearEstablished: number;
		IsWorkforceSolutionsVendor: boolean;
		StarLevel: number;
		CenterTypeId: number;
		CenterStatusId: number;
		AddressId: number;
		AddressDto: AddressDto = new AddressDto();
		ClassroomCount: number;
		InfantCount: number;
		PreschoolerCount: number;
		FamilyCount: number;
		ToddlerCount: number;
		TabletCount: number;
		LicenseWaiver: Date;
		TechRequirementsMet: boolean;
		PrimaryContactId: number;
		PrimaryContact: UserDto = new UserDto();
		PrimaryContactTitleId: number;
		SecondaryContactId: number;
		SecondaryContactTitleId: number;
		SecondaryContact: UserDto = new UserDto();
		NewBusinessSpecialistId: number;
		NewBusinessSpecialist: UserDto = new UserDto();
		EarlyChildhoodAssessorId: number;
		EarlyChildhoodAssessor: UserDto = new UserDto();
		SeniorMentorId: number;
		SeniorMentor: UserDto = new UserDto();
		FamilySupportSpecialistId: number;
		FamilySupportSpecialist: UserDto = new UserDto();
		LastActivityDate: Date;
		CenterProgramsDtos: CenterProgramDto[] = [];
		CenterTypeList: ValueTextDto[] = [];
		CenterStatusList: ValueTextDto[] = [];
		ContactTypeList: ValueTextDto[] = [];
	}

	export class CenterProgramDto {
		ProgramId: number;
		ProgramName: string;
		IsSelected: boolean;
	}

	export class CenterSearchRow {
		Id: number;
		Name: string;
		License: number;
		CreatedDate: Date;
		LastActivityDate: Date;
		IsActive: boolean = true;
		PrimaryContactId: number;
		SecondaryContactId: number;
		SeniorMentorId: number;
	}

	export class ChildDto {
		Id: number;
		FirstName: string;
		MiddleInitial: string;
		LastName: string;
		DateOfBirth: DateDto = new DateDto();
		GenderId: number;
		EthnicityId: number;
		RaceId: number;
		ParentalConsentToShareData: boolean;
		ParentalConsentToPublicity: boolean;
		AddressId: number;
		IsActive: boolean = true;
		IsDeleted: boolean;
		ClassDto: ClassDto = new ClassDto();
		CenterDto: CenterDto = new CenterDto();
		AddressDto: AddressDto = new AddressDto();
		GenderList: ValueTextDto[] = [];
		EthnicityList: ValueTextDto[] = [];
		RaceList: ValueTextDto[] = [];
		Guardians: ChildGuardianDto[] = [];
		ChildRelationshipList: ValueTextDto[] = [];
	}

	export class ChildGuardianDto {
		ChildId: number;
		GuardianId: number;
		ChildRelationshipId: number;
		ChildRelationshipOther: string;
		PrimaryFlag: boolean;
		ChildDto: ChildDto = new ChildDto();
		GuardianDto: GuardianDto = new GuardianDto();
		ChildRelationshipList: ValueTextDto[] = [];
	}

	export class ChildSearchRow {
		Id: number;
		FirstName: string;
		MiddleInitial: string;
		LastName: string;
		DateOfBirth: Date;
		CenterName: string;
		Teacher: string;
		PrimaryGuardian: string;
		IsActive: boolean = true;
		ActiveClassId: number;
		CenterId: number;
		CenterPrimaryContactId: number;
		CenterSecondaryContactId: number;
		CenterSeniorMentorId: number;
	}

	export class ClassAgeGroupDto {
		ClassId: number;
		AgeGroupId: number;
		IsActive: boolean = true;
		CreatedBy: number;
		CreatedDate: Date;
		ClassDto: ClassDto = new ClassDto();
		AgeGroupDto: AgeGroupDto = new AgeGroupDto();
	}

	export class ClassDto {
		Id: number;
		Name: string;
		Description: string;
		CenterId: number;
		AgeGroupId: number;
		TabletCount: number;
		LicensingReqMet: boolean;
		LicensingWaiverExpDate: DateDto = new DateDto();
		StartDate: Date;
		EndDate: Date;
		IsActive: boolean = true;
		IsDeleted: boolean;
		CreatedBy: number;
		CreatedDate: Date;
		UpdatedBy: number;
		UpdatedDate: Date;
		Center: CenterDto = new CenterDto();
		Teachers: ClassTeacherDto[] = [];
		ClassRoster: ClassRosterDto[] = [];
		AgeGroups: number[] = [];
		AgeGroupList: ValueTextDto[] = [];
	}

	export class ClassificationDto {
		Id: number;
		Name: string;
		Description: string;
		IconImageName: string;
	}

	export class ClassRosterDto {
		Id: number;
		ClassId: number;
		ChildId: number;
		IsActive: boolean = true;
		ClassDto: ClassDto = new ClassDto();
		ChildDto: ChildDto = new ChildDto();
	}

	export class ClassSearchRow {
		Id: number;
		Name: string;
		CenterName: string;
		AgeGroup: string;
		PrimaryTeacher: string;
		ChildCount: number;
		IsActive: boolean = true;
		CenterPrimaryContactId: number;
		CenterSecondaryContactId: number;
		CenterSeniorMentorId: number;
	}

	export class ClassTeacherDto {
		Id: number;
		ClassId: number;
		TeacherId: number;
		PrimaryFlag: boolean;
		IsActive: boolean = true;
		CreatedBy: number;
		CreatedDate: Date;
		ClassDto: ClassDto = new ClassDto();
		TeacherDto: TeacherDto = new TeacherDto();
	}

	export class CountryDto {
		Id: number;
		Name: string;
		IsInternational: boolean;
		IsActive: boolean = true;
	}

	export class CountrySearchRow {
		Id: number;
		Name: string;
		IsActive: boolean = true;
	}

	export class CountryValueTextDto {
		Value: number;
		IsInternational: boolean;
		Text: string;
	}

	export class CountWidgetDto {
		ReferenceName: string;
		DisplayName: string;
		ViewName: string;
		Link: string;
		Count: number;
		Id: number;
		Order: number;
		WidgetType: WidgetType;
		Selected: boolean;
	}

	export class DashboardDto {
		Widgets: WidgetDto[] = [];
	}

	export class DateDto implements IComparable {
		Day: number;
		Month: number;
		Year: number;
	}

	export class EmailDto {
		From: string;
		Subject: string;
		Body: string;
		Recipients: string[] = [];
		Attachments: FileDto[] = [];
	}

	export class EmailResultDto {
		Succeeded: boolean;
		ResponseInformation: string;
		Exception: any;
	}

	export class ErrorLogSearchRow {
		Id: number;
		CreationDate: Date;
		ApplicationName: string;
		URL: string;
		User: string;
		EnvironmentInfo: string;
		Message: string;
	}

	export class ExampleSearchRow {
		Id: number;
		CreateDate: Date;
		Key: string;
		DisplayText: string;
		Value: string;
		ExampleLookupDisplayText: string;
	}

	export class FeedbackSurveyInfoDto {
		FeedbackSurveyLinkURL: string;
		EffectiveDate: Date;
	}

	export class FileDto {
		Bytes: Blob;
		Base64String: string;
		Name: string;
		MimeType: string;
	}

	export class FileLinkSearchRow {
		FileLinkId: number;
		LinkId: number;
		LinkType: string;
		FileId: number;
		DisplayName: string;
		Description: string;
		CreatedOn: Date;
		CreatedBy: string;
	}

	export class FileSearchRow {
		FileId: number;
		DisplayName: string;
		Description: string;
		CreatedOn: Date;
		CreatedBy: string;
	}

	export class FrequentQuestionDto {
		Id: number;
		Question: string;
		Answer: string;
		ValidFrom: Date;
		ValidTo: Date;
		CreatedByUserName: string;
		CreatedDate: Date;
		LastModifiedUserName: string;
		LastModifiedDate: Date;
		Active: boolean;
	}

	export class GuardianDto {
		Id: number;
		FirstName: string;
		LastName: string;
		Email: string;
		Phone: string;
		GenderId: number;
		EthnicityId: number;
		RaceId: number;
		EducationLevelId: number;
		ChildRelationshipId: number;
		ChildRelationshipOther: string;
		PrimaryLanguageId: number;
		AnnualHouseholdIncomeId: number;
		AddressId: number;
		IsActive: boolean = true;
		IsDeleted: boolean;
		Services: number[] = [];
		OtherServices: string;
		AddressDto: AddressDto = new AddressDto();
		Children: ChildGuardianDto[] = [];
		GenderList: ValueTextDto[] = [];
		EthnicityList: ValueTextDto[] = [];
		RaceList: ValueTextDto[] = [];
		EducationLevelList: ValueTextDto[] = [];
		IncomeRangeList: ValueTextDto[] = [];
		LanguageList: ValueTextDto[] = [];
		ChildRelationshipList: ValueTextDto[] = [];
		ServicesList: ValueTextDto[] = [];
	}

	export class GuardianSearchRow {
		Id: number;
		FirstName: string;
		LastName: string;
		IsActive: boolean = true;
	}

	export class LinkFileDto {
		LinkFileType: string;
		LinkFileTypeKey: number;
	}

	export class MenuDto {
		MenuItems: MenuItemDto[] = [];
	}

	export class MenuItemDto {
		Id: number;
		Type: MenuItemType;
		ChildrenId: number[] = [];
		ParentId: number;
		DisplayText: string;
		Path: string;
		IsActive: boolean = true;
		SecurityCheckpointId: number;
		OrderBy: number;
		Children: MenuItemDto[] = [];
		SecurityCheckpoint: SecurityCheckpointDto = new SecurityCheckpointDto();
		Parent: MenuItemDto = new MenuItemDto();
	}

	export class ProgramDto {
		Id: number;
		Name: string;
		Description: string;
		IsActive: boolean = true;
	}

	export class ProgramSearchRow {
		Id: number;
		Name: string;
		Description: string;
		IsActive: boolean = true;
	}

	export class RoleDto {
		Id: number;
		Name: string;
		Checkpoints: SecurityCheckpointDto[] = [];
	}

	export class RoleSearchRow {
		Id: number;
		Name: string;
	}

	export class SearchGridFormatTestSearchRow {
		Id: number;
		Name: string;
		Image: Blob;
		Checkbox: boolean;
	}

	export class SearchTestSearchRow {
		Id: number;
		UserId: number;
		Name: string;
		BigInt: number;
		Date: Date;
		DateTime2: Date;
		DateTimeOffset: Date;
		Guid: string;
		Active: boolean;
		CreatedDate: Date;
		CreatedBy: number;
	}

	export class SecurityCheckpointAccessDto {
		Name: string;
		HasAccess: boolean;
	}

	export class SecurityCheckpointCSVDto {
		CommandName: string;
		Description: string;
		Roles: string;
	}

	export class SecurityCheckpointDto {
		Id: number;
		Name: string;
		Description: string;
	}

	export class SecurityCheckpointSearchRow {
		Id: number;
		Name: string;
		Description: string;
	}

	export class ServeFileDownloadResponse {
		Bytes: Blob;
		ContentType: string;
		DisplayName: string;
	}

	export class TeacherDto {
		Id: number;
		UserId: number;
		UserDto: UserDto = new UserDto();
		CenterId: number;
		CenterDto: CenterDto = new CenterDto();
		TeachingStartDate: DateDto = new DateDto();
		PayRangeId: number;
		CurrentPosition: string;
		CurrentPositions: number[] = [];
		IsActive: boolean = true;
		PrimaryFlag: boolean;
		PayRangeList: ValueTextDto[] = [];
		Positions: ValueTextDto[] = [];
	}

	export class TeacherSearchRow {
		Id: number;
		FirstName: string;
		LastName: string;
		CenterId: number;
		CenterName: string;
		CurrentPosition: string;
		IsActive: boolean = true;
		PrimaryContactId: number;
		SecondaryContactId: number;
		SeniorMentorId: number;
	}

	export class UserAlertDto {
		AlertID: number;
		AlertType: string;
		AlertDisplayStyle: string;
		AlertDate: Date;
		AlertMessage: string;
		Language: string;
		MessageReadByUser: boolean;
		MessageReadDate: Date;
		AlertDateReadable: string;
	}

	export class UserDto {
		Id: number;
		Username: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Phone: string;
		AddressId: number;
		GenderId: number;
		EthnicityId: number;
		RaceId: number;
		EducationLevelId: number;
		Active: boolean;
		LogCommands: boolean;
		UserRoleDtos: UserRoleDto[] = [];
		Address: AddressDto = new AddressDto();
		Genders: ValueTextDto[] = [];
		Ethnicities: ValueTextDto[] = [];
		Races: ValueTextDto[] = [];
		EducationLevels: ValueTextDto[] = [];
	}

	export class UserFileDto {
		Id: number;
		UserId: number;
		FileLinkId: number;
		FileId: number;
		FileName: string;
		FileType: string;
		FileTypeEnum: UserFileType;
		Status: string;
		StatusEnum: UserFileStatus;
		TimeQueued: Date;
	}

	export class UserFilesDto {
		UserFiles: UserFileDto[] = [];
	}

	export class UserFileSearchRow {
		Id: number;
		FileLinkId: number;
		FileId: number;
		UserId: number;
		FileName: string;
		Status: string;
		TimeQueued: Date;
	}

	export class UserInfoDto {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Roles: ValueTextDto[] = [];
		EmailAddress: string;
		LogCommands: boolean;
		Checkpoints: SecurityCheckpointAccessDto[] = [];
	}

	export class UserProfileDto {
		UserId: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		BadgeDisplay: string;
		DisplayName: string;
		Email: string;
		IsSystemAdmin: boolean;
		IsBASAdmin: boolean;
		UserRoles: string[] = [];
	}

	export class UserRoleDto {
		RoleId: number;
		RoleName: string;
		IsSelected: boolean;
	}

	export class UserSearchRow {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Active: boolean;
	}

	export class UserWithRolesSearchRow {
		Id: number;
		UserName: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Active: boolean;
		Roles: string;
	}

	export class ValueLabelDto {
		value: any;
		label: string;
	}

	export class ValueSelectedDto {
		Value: any;
		Selected: boolean;
	}

	export class ValueTextDescriptionDto {
		Value: any;
		Text: string;
		Description: string;
	}

	export class ValueTextDto {
		Value: any;
		Text: string;
	}

	export class ValueTextSelectedDto {
		Value: any;
		Text: string;
		Selected: boolean;
	}

	export class WidgetDto {
		Id: number;
		Order: number;
		WidgetType: WidgetType;
		Selected: boolean;
	}

	export interface IComparable {
	}

	export interface ICommandRequest {
	}

	export interface IUserFileCommandRequest {
		UserName: string;
		UserFileId: number;
	}

	export interface ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria;
	}

	export interface ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[];
	}

	export class AdminCommandRequest implements ICommandRequest {
	}

	export class AdminGetFeedbackSurveyLinkCommandRequest implements ICommandRequest {
	}

	export class AdminSaveFeedbackSurveyLinkCommandRequest implements ICommandRequest {
		FeedbakSurveyInfo: FeedbackSurveyInfoDto = new FeedbackSurveyInfoDto();
	}

	export class AdminSaveFrequentQuestionListCommandRequest implements ICommandRequest {
		FrequentlyAskedQuestionList: FrequentQuestionDto[] = [];
	}

	export class AuditUserDocumentUsageCommandRequest implements ICommandRequest {
		DocumentID: number;
		DocumentUrl: string;
		Language: string;
	}

	export class BookmarkDocumentCommandRequest implements ICommandRequest {
		BookmarkedDocuments: AcceleratorDocumentDto[] = [];
	}

	export class CreateLocalUserAccountCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		ServiceToken: string;
	}

	export class CreateTestDummyCommandQueueCommandRequest implements ICommandRequest {
	}

	export class DeleteCheckpointCommandRequest implements ICommandRequest {
		CommandSecurityRoleId: number;
	}

	export class DeleteExampleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteFileLinkCommandRequest implements ICommandRequest {
		FileLinkId: number;
	}

	export class DeleteProgramCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class DeleteRoleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class EditFileCommandRequest implements ICommandRequest {
		FileId: number;
		DisplayName: string;
		Description: string;
	}

	export class ExecuteAllPendingQueuesCommandRequest implements ICommandRequest {
	}

	export class ExecuteNextQueueEntryCommandRequest implements ICommandRequest {
		QueueDefinition: BatchProcessingQueueDefinition;
		QueueEntryId: number;
	}

	export class ExecutePendingQueueEntriesCommandRequest implements ICommandRequest {
		QueueDefinition: BatchProcessingQueueDefinition;
	}

	export class ExportCheckpointsToCSVCommandRequest implements ICommandRequest {
	}

	export class GenerateAttendanceSheetCommandRequest implements ICommandRequest {
		date: Date;
	}

	export class GenerateDailyDefaultAttendanceSheetsCommandRequest implements ICommandRequest {
	}

	export class GetAcceleratorDocumentClassificationListCommandRequest implements ICommandRequest {
		Language: string;
	}

	export class GetAcceleratorDocumentListCommandRequest implements ICommandRequest {
		ClassificationName: string;
		Language: string;
	}

	export class GetApplicationSettingCommandRequest implements ICommandRequest {
		SettingName: string;
	}

	export class GetAttendanceSheetCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCenterCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCenterStatusCommandRequest implements ICommandRequest {
	}

	export class GetCheckpointCommandRequest implements ICommandRequest {
		SecurityCheckpointId: number;
	}

	export class GetChildCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetClassCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCountryCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetCurrentUserProfileCommandRequest implements ICommandRequest {
	}

	export class GetDashboardCommandRequest implements ICommandRequest {
	}

	export class GetExampleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetExceptionCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetFileCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetFrequentlyAskedQuestionListCommandRequest implements ICommandRequest {
		Language: string;
	}

	export class GetGuardianCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetMenuCommandRequest implements ICommandRequest {
	}

	export class GetMyProfileCommandRequest implements ICommandRequest {
	}

	export class GetProgramCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetQueueDetailCommandRequest implements ICommandRequest {
		QueueName: string;
	}

	export class GetRecentUserFilesCommandRequest implements ICommandRequest {
	}

	export class GetReleaseNumberCommandRequest implements ICommandRequest {
	}

	export class GetRoleCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetRolesCommandRequest implements ICommandRequest {
	}

	export class GetTeacherCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetUserAcceleratorAlertsCommandRequest implements ICommandRequest {
		UnreadMessagesOnly: boolean;
		Language: string;
	}

	export class GetUserCommandRequest implements ICommandRequest {
		Id: number;
	}

	export class GetUserWidgetSelectionsCommandRequest implements ICommandRequest {
	}

	export class ImportCheckpointsFromCSVCommandRequest implements ICommandRequest {
		fileBytes: Blob;
	}

	export class IsUserAuthenticatedCommandRequest implements ICommandRequest {
	}

	export class LoginCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		ServiceToken: string;
	}

	export class LogoutCommandRequest implements ICommandRequest {
	}

	export class LogTypeScriptErrorCommandRequest implements ICommandRequest {
		Message: string;
		Type: string;
		Url: string;
		Stack: string;
	}

	export class OverdueAttendanceSheetCheckCommandRequest implements ICommandRequest {
	}

	export class PdfSearchCommandRequest implements ICommandRequest,IUserFileCommandRequest {
		CommandName: string;
		SearchType: string;
		UserName: string;
		UserFileId: number;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class ProcessEmailQueueCommandRequest implements ICommandRequest {
	}

	export class QueueUserFileCommandRequest implements ICommandRequest {
		UserFileType: UserFileType;
		FileCommandName: string;
		FileCommandPayloadAsJson: string;
	}

	export class RemoveUserFavoriteDocumentCommandRequest implements ICommandRequest {
		Document: AcceleratorDocumentDto = new AcceleratorDocumentDto();
	}

	export class SaveApplicationSettingCommandRequest implements ICommandRequest {
		Id: number;
		Name: string;
		Value: string;
	}

	export class SaveAttendanceSheetCommandRequest implements ICommandRequest {
		AttendanceSheet: AttendanceSheetDto = new AttendanceSheetDto();
		IsTeacher: boolean;
	}

	export class SaveCenterCommandRequest implements ICommandRequest {
		Center: CenterDto = new CenterDto();
		CenterPrograms: CenterProgramDto[] = [];
	}

	export class SaveCheckpointCommandRequest implements ICommandRequest {
		Roles: ValueTextSelectedDto[] = [];
		SecurityCheckpoint: SecurityCheckpointDto = new SecurityCheckpointDto();
	}

	export class SaveChildCommandRequest implements ICommandRequest {
		Child: ChildDto = new ChildDto();
	}

	export class SaveClassCommandRequest implements ICommandRequest {
		Class: ClassDto = new ClassDto();
	}

	export class SaveCountryCommandRequest implements ICommandRequest {
		Country: CountryDto = new CountryDto();
	}

	export class SaveExampleCommandRequest implements ICommandRequest {
		Id: number;
		DisplayText: string;
		Value: string;
		ExampleLookupId: number;
	}

	export class SaveFileCommandRequest implements ICommandRequest {
		FileName: string;
		ContentType: string;
		Description: string;
	}

	export class SaveFileLinkCommandRequest implements ICommandRequest {
		LinkType: string;
		LinkTypeKey: number;
		FileId: number;
	}

	export class SaveGuardianCommandRequest implements ICommandRequest {
		Guardian: GuardianDto = new GuardianDto();
	}

	export class SaveProgramCommandRequest implements ICommandRequest {
		Program: ProgramDto = new ProgramDto();
	}

	export class SaveRoleCommandRequest implements ICommandRequest {
		Role: RoleDto = new RoleDto();
		RoleWidgets: ValueTextSelectedDto[] = [];
	}

	export class SaveTeacherCommandRequest implements ICommandRequest {
		Teacher: TeacherDto = new TeacherDto();
	}

	export class SaveUserCommandRequest implements ICommandRequest {
		UserDto: UserDto = new UserDto();
	}

	export class SaveUserSignUpCommandRequest implements ICommandRequest {
		Username: string;
		Password: string;
		FirstName: string;
		LastName: string;
		Email: string;
		Token: string;
		Roles: ValueTextDto[] = [];
	}

	export class SaveUserWidgetOrderCommandRequest implements ICommandRequest {
		WidgetIds: number[] = [];
	}

	export class SaveUserWidgetSelectionsCommandRequest implements ICommandRequest {
		SelectedWidgetIds: number[] = [];
	}

	export class SearchAttendanceCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SearchCenterCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SearchChildCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SearchClassCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SearchObjectsCommandRequest implements ICommandRequest {
		SearchCriteria: SearchCriteria = new SearchCriteria();
		Objects: any[] = [];
	}

	export class SearchTeacherCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SearchUserFilesCommandRequest implements ICommandRequest,ISearchCommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class SetUserAcceleratorAlertReadStatusCommandRequest implements ICommandRequest {
		AlertID: number;
		MarkAsRead: boolean;
	}

	export class TestDummyCommandRequest implements ICommandRequest {
		MyString: string;
		MyInt: number;
		MyNullableInt: number;
	}

	export class TriggerServerExceptionCommandRequest implements ICommandRequest {
	}

	export class UnusedSecurityCheckpointSearchCommandRequest implements ICommandRequest {
		SearchType: string;
		SearchCriteria: SearchCriteria = new SearchCriteria();
	}

	export class AdminCommandResponse {
	}

	export class AdminGetFeedbackSurveyLinkCommandResponse {
		FeedbackSurveyInfo: FeedbackSurveyInfoDto = new FeedbackSurveyInfoDto();
	}

	export class AdminSaveFeedbackSurveyLinkCommandResponse {
	}

	export class AdminSaveFrequentQuestionListCommandResponse {
		FrequentlyAskedQuestions: FrequentQuestionDto[] = [];
	}

	export class AuditUserDocumentUsageCommandResponse {
	}

	export class BookmarkDocumentCommandResponse {
	}

	export class CreateLocalUserAccountCommandResponse {
	}

	export class CreateTestDummyCommandQueueCommandResponse {
	}

	export class DeleteCheckpointCommandResponse {
	}

	export class DeleteFileLinkCommandResponse {
	}

	export class DeleteProgramCommandResponse {
	}

	export class DeleteRoleCommandResponse {
	}

	export class EditFileCommandResponse {
	}

	export class ExecuteAllPendingQueuesCommandResponse {
	}

	export class ExecuteNextQueueEntryCommandResponse {
		ProcessedQueueEntryId: number;
		WasAQueueEntryProcessed: boolean;
	}

	export class ExecutePendingQueueEntriesCommandResponse {
	}

	export class ExportCheckpointsToCSVCommandResponse {
		FileName: string;
		Bytes: Blob;
		MimeType: string;
	}

	export class GenerateAttendanceSheetCommandResponse {
		AttendanceSheetDto: AttendanceSheetDto = new AttendanceSheetDto();
	}

	export class GenerateDailyDefaultAttendanceSheetsCommandResponse {
		TimesheetDate: Date;
		AttendanceSheetsAvailable: number;
	}

	export class GetAcceleratorDocumentClassificationListCommandResponse {
		Classifications: ClassificationDto[] = [];
	}

	export class GetAcceleratorDocumentListCommandResponse {
		Documents: AcceleratorDocumentDto[] = [];
	}

	export class GetApplicationSettingCommandResponse {
		Id: number;
		Name: string;
		Value: string;
	}

	export class GetAttendanceSheetCommandResponse {
		AttendanceSheetDto: AttendanceSheetDto = new AttendanceSheetDto();
	}

	export class GetCenterCommandResponse {
		CenterDto: CenterDto = new CenterDto();
	}

	export class GetCenterStatusCommandResponse {
		CenterStatus: ValueTextDto[] = [];
	}

	export class GetCheckpointCommandResponse {
		Roles: ValueTextSelectedDto[] = [];
		AvailableCommands: ValueTextDto[] = [];
		SecurityCheckpoint: SecurityCheckpointDto = new SecurityCheckpointDto();
	}

	export class GetChildCommandResponse {
		ChildDto: ChildDto = new ChildDto();
	}

	export class GetClassCommandResponse {
		ClassDto: ClassDto = new ClassDto();
	}

	export class GetCountryCommandResponse {
		Country: CountryDto = new CountryDto();
	}

	export class GetCurrentUserProfileCommandResponse {
		UserProfile: UserProfileDto = new UserProfileDto();
	}

	export class GetDashboardCommandResponse {
		Dashboard: DashboardDto = new DashboardDto();
	}

	export class GetExampleCommandResponse {
		Id: number;
		DisplayText: string;
		Value: string;
		ExampleLookupId: number;
		ExampleLookups: ValueLabelDto[] = [];
	}

	export class GetExceptionCommandResponse {
		Id: number;
		CreationDate: string;
		ApplicationName: string;
		MachineName: string;
		Type: string;
		User: string;
		URL: string;
		HTTPMethod: string;
		Message: string;
		Detail: string;
	}

	export class GetFileCommandResponse {
		Id: number;
		DisplayName: string;
		ReferenceName: string;
		CreatedOn: Date;
		CreatedBy: string;
		ContentType: string;
		Description: string;
	}

	export class GetFrequentlyAskedQuestionListCommandResponse {
		FrequentlyAskedQuestions: FrequentQuestionDto[] = [];
	}

	export class GetGuardianCommandResponse {
		GuardianDto: GuardianDto = new GuardianDto();
	}

	export class GetMenuCommandResponse {
		Menu: MenuDto = new MenuDto();
	}

	export class GetMyProfileCommandResponse {
		UserDto: UserDto = new UserDto();
	}

	export class GetProgramCommandResponse {
		Program: ProgramDto = new ProgramDto();
	}

	export class GetQueueDetailCommandResponse {
	}

	export class GetRecentUserFilesCommandResponse {
		UserFiles: UserFilesDto = new UserFilesDto();
	}

	export class GetReleaseNumberCommandResponse {
		ReleaseId: string;
	}

	export class GetRoleCommandResponse {
		Role: RoleDto = new RoleDto();
		RoleWidgets: ValueTextSelectedDto[] = [];
	}

	export class GetRolesCommandResponse {
		Roles: ValueTextDto[] = [];
	}

	export class GetTeacherCommandResponse {
		TeacherDto: TeacherDto = new TeacherDto();
	}

	export class GetUserAcceleratorAlertsCommandResponse {
		UserAlerts: UserAlertDto[] = [];
	}

	export class GetUserCommandResponse {
		UserDto: UserDto = new UserDto();
	}

	export class GetUserWidgetSelectionsCommandResponse {
		Widgets: WidgetDto[] = [];
	}

	export class ImportCheckpointsFromCSVCommandResponse {
	}

	export class IsUserAuthenticatedCommandResponse {
		IsAuthenticated: boolean;
		UserInfo: UserInfoDto = new UserInfoDto();
	}

	export class LoginCommandResponse {
		UserInfo: UserInfoDto = new UserInfoDto();
		IsAuthenticated: boolean;
		FailedAuthMessage: string;
	}

	export class OverdueAttendanceSheetCheckCommandResponse {
		OverdueAttendanceSheetsExists: boolean;
	}

	export class PdfSearchCommandResponse {
		FileDto: FileDto = new FileDto();
	}

	export class ProcessEmailQueueCommandResponse {
	}

	export class QueueUserFileCommandResponse {
		UserFileId: number;
	}

	export class RemoveUserFavoriteDocumentCommandResponse {
	}

	export class SaveApplicationSettingCommandResponse {
		Id: number;
	}

	export class SaveAttendanceSheetCommandResponse {
		Id: number;
	}

	export class SaveCenterCommandResponse {
		Id: number;
	}

	export class SaveCheckpointCommandResponse {
		Id: number;
	}

	export class SaveChildCommandResponse {
		Id: number;
	}

	export class SaveClassCommandResponse {
		Id: number;
	}

	export class SaveCountryCommandResponse {
		Id: number;
	}

	export class SaveExampleCommandResponse {
		Id: number;
	}

	export class SaveFileCommandResponse {
		Id: number;
		ReferenceName: string;
	}

	export class SaveFileLinkCommandResponse {
		Id: number;
	}

	export class SaveGuardianCommandResponse {
		Id: number;
	}

	export class SaveProgramCommandResponse {
		Id: number;
	}

	export class SaveRoleCommandResponse {
		Id: number;
	}

	export class SaveTeacherCommandResponse {
		Id: number;
	}

	export class SaveUserCommandResponse {
		Id: number;
	}

	export class SaveUserSignUpCommandResponse {
		FailedAuthMessage: string;
		IsAuthenticated: boolean;
	}

	export class SaveUserWidgetOrderCommandResponse {
	}

	export class SaveUserWidgetSelectionsCommandResponse {
	}

	export class SearchAttendanceCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SearchCenterCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SearchChildCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SearchClassCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SearchObjectsCommandResponse {
		Results: IPagedResult;
	}

	export class SearchTeacherCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SearchUserFilesCommandResponse implements ISearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export class SetUserAcceleratorAlertReadStatusCommandResponse {
	}

	export class TestDummyCommandResponse {
		MyString: string;
		MyInt: number;
		MyNullableInt: number;
	}

	export class TriggerServerExceptionCommandResponse {
	}

	export class UnusedSecurityCheckpointSearchCommandResponse {
		PagedResult: IPagedResult;
		Columns: ColumnInformation[] = [];
	}

	export enum BatchProcessingQueueDefinition {
		TestDummy = 1,
		Email = 2,
		UserFile = 3,
	}

	export enum QueueEntryStatus {
		Pending = 0,
		Executing = 1,
		CompletedSuccessfully = 2,
		CompletedWithError = 3,
	}

	export enum ClaimsSource {
		Database = 0,
		JWTToken = 1,
		ActiveDirectory = 2,
	}

	export enum AuthenticationType {
		Database = 0,
		LoginActiveDirectory = 1,
		AutoActiveDirectory = 2,
		AzureActiveDirectory = 3,
		B2C = 4,
	}

	export enum MenuItemType {
		Entry = 0,
		Separator = 1,
		DropDown = 2,
	}

	export enum EmailStatus {
		Pending = 0,
		Sent = 1,
		Failed = 2,
	}

	export enum EmailType {
		Generic = 0,
		BusinessRegistrationRequest = 1,
		InternalBusinessRegistrationRequest = 2,
		BusinessRegistrationApproval = 3,
	}

	export enum UserFileStatus {
		Pending = 0,
		Complete = 1,
		Failed = 2,
	}

	export enum UserFileType {
		SearchPdf = 1,
		SearchCsv = 2,
	}

	export enum AppRole {
		Admin = 1,
		User = 2,
		SystemAdmin = 3,
		Executive = 4,
		Mentor = 5,
		FamilyEmpowerment = 6,
		BusinessDevelopment = 7,
		CenterDirector = 8,
		CenterStaff = 9,
		CenterTeacher = 10,
	}

	export enum WidgetType {
		Count = 0,
	}

	export let BatchProcessingQueueDefinitionDisplayOptions = [
		{ name: "TestDummy", value: "TestDummy", enum: BatchProcessingQueueDefinition.TestDummy },
		{ name: "Email", value: "Email", enum: BatchProcessingQueueDefinition.Email },
		{ name: "UserFile", value: "UserFile", enum: BatchProcessingQueueDefinition.UserFile },
	];

	export let QueueEntryStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: QueueEntryStatus.Pending },
		{ name: "Executing", value: "Executing", enum: QueueEntryStatus.Executing },
		{ name: "CompletedSuccessfully", value: "CompletedSuccessfully", enum: QueueEntryStatus.CompletedSuccessfully },
		{ name: "CompletedWithError", value: "CompletedWithError", enum: QueueEntryStatus.CompletedWithError },
	];

	export let ClaimsSourceDisplayOptions = [
		{ name: "Database", value: "Database", enum: ClaimsSource.Database },
		{ name: "JWTToken", value: "JWTToken", enum: ClaimsSource.JWTToken },
		{ name: "ActiveDirectory", value: "ActiveDirectory", enum: ClaimsSource.ActiveDirectory },
	];

	export let AuthenticationTypeDisplayOptions = [
		{ name: "Database", value: "Database", enum: AuthenticationType.Database },
		{ name: "LoginActiveDirectory", value: "LoginActiveDirectory", enum: AuthenticationType.LoginActiveDirectory },
		{ name: "AutoActiveDirectory", value: "AutoActiveDirectory", enum: AuthenticationType.AutoActiveDirectory },
		{ name: "AzureActiveDirectory", value: "AzureActiveDirectory", enum: AuthenticationType.AzureActiveDirectory },
		{ name: "B2C", value: "B2C", enum: AuthenticationType.B2C },
	];

	export let MenuItemTypeDisplayOptions = [
		{ name: "Entry", value: "Entry", enum: MenuItemType.Entry },
		{ name: "Separator", value: "Separator", enum: MenuItemType.Separator },
		{ name: "DropDown", value: "DropDown", enum: MenuItemType.DropDown },
	];

	export let EmailStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: EmailStatus.Pending },
		{ name: "Sent", value: "Sent", enum: EmailStatus.Sent },
		{ name: "Failed", value: "Failed", enum: EmailStatus.Failed },
	];

	export let EmailTypeDisplayOptions = [
		{ name: "Generic", value: "Generic", enum: EmailType.Generic },
		{ name: "BusinessRegistrationRequest", value: "BusinessRegistrationRequest", enum: EmailType.BusinessRegistrationRequest },
		{ name: "InternalBusinessRegistrationRequest", value: "InternalBusinessRegistrationRequest", enum: EmailType.InternalBusinessRegistrationRequest },
		{ name: "BusinessRegistrationApproval", value: "BusinessRegistrationApproval", enum: EmailType.BusinessRegistrationApproval },
	];

	export let UserFileStatusDisplayOptions = [
		{ name: "Pending", value: "Pending", enum: UserFileStatus.Pending },
		{ name: "Complete", value: "Complete", enum: UserFileStatus.Complete },
		{ name: "Failed", value: "Failed", enum: UserFileStatus.Failed },
	];

	export let UserFileTypeDisplayOptions = [
		{ name: "SearchPdf", value: "SearchPdf", enum: UserFileType.SearchPdf },
		{ name: "SearchCsv", value: "SearchCsv", enum: UserFileType.SearchCsv },
	];

	export let AppRoleDisplayOptions = [
		{ name: "Admin", value: "Admin", enum: AppRole.Admin },
		{ name: "User", value: "User", enum: AppRole.User },
		{ name: "SystemAdmin", value: "SystemAdmin", enum: AppRole.SystemAdmin },
		{ name: "Executive", value: "Executive", enum: AppRole.Executive },
		{ name: "Mentor", value: "Mentor", enum: AppRole.Mentor },
		{ name: "FamilyEmpowerment", value: "FamilyEmpowerment", enum: AppRole.FamilyEmpowerment },
		{ name: "BusinessDevelopment", value: "BusinessDevelopment", enum: AppRole.BusinessDevelopment },
		{ name: "CenterDirector", value: "CenterDirector", enum: AppRole.CenterDirector },
		{ name: "CenterStaff", value: "CenterStaff", enum: AppRole.CenterStaff },
		{ name: "CenterTeacher", value: "CenterTeacher", enum: AppRole.CenterTeacher },
	];

	export let WidgetTypeDisplayOptions = [
		{ name: "Count", value: "Count", enum: WidgetType.Count },
	];

	export let BatchProcessingQueueDefinitionDisplayMap = {
		[BatchProcessingQueueDefinition.TestDummy]: "TestDummy",
		[BatchProcessingQueueDefinition.Email]: "Email",
		[BatchProcessingQueueDefinition.UserFile]: "UserFile",
	};

	export let QueueEntryStatusDisplayMap = {
		[QueueEntryStatus.Pending]: "Pending",
		[QueueEntryStatus.Executing]: "Executing",
		[QueueEntryStatus.CompletedSuccessfully]: "CompletedSuccessfully",
		[QueueEntryStatus.CompletedWithError]: "CompletedWithError",
	};

	export let ClaimsSourceDisplayMap = {
		[ClaimsSource.Database]: "Database",
		[ClaimsSource.JWTToken]: "JWTToken",
		[ClaimsSource.ActiveDirectory]: "ActiveDirectory",
	};

	export let AuthenticationTypeDisplayMap = {
		[AuthenticationType.Database]: "Database",
		[AuthenticationType.LoginActiveDirectory]: "LoginActiveDirectory",
		[AuthenticationType.AutoActiveDirectory]: "AutoActiveDirectory",
		[AuthenticationType.AzureActiveDirectory]: "AzureActiveDirectory",
		[AuthenticationType.B2C]: "B2C",
	};

	export let MenuItemTypeDisplayMap = {
		[MenuItemType.Entry]: "Entry",
		[MenuItemType.Separator]: "Separator",
		[MenuItemType.DropDown]: "DropDown",
	};

	export let EmailStatusDisplayMap = {
		[EmailStatus.Pending]: "Pending",
		[EmailStatus.Sent]: "Sent",
		[EmailStatus.Failed]: "Failed",
	};

	export let EmailTypeDisplayMap = {
		[EmailType.Generic]: "Generic",
		[EmailType.BusinessRegistrationRequest]: "BusinessRegistrationRequest",
		[EmailType.InternalBusinessRegistrationRequest]: "InternalBusinessRegistrationRequest",
		[EmailType.BusinessRegistrationApproval]: "BusinessRegistrationApproval",
	};

	export let UserFileStatusDisplayMap = {
		[UserFileStatus.Pending]: "Pending",
		[UserFileStatus.Complete]: "Complete",
		[UserFileStatus.Failed]: "Failed",
	};

	export let UserFileTypeDisplayMap = {
		[UserFileType.SearchPdf]: "SearchPdf",
		[UserFileType.SearchCsv]: "SearchCsv",
	};

	export let AppRoleDisplayMap = {
		[AppRole.Admin]: "Admin",
		[AppRole.User]: "User",
		[AppRole.SystemAdmin]: "SystemAdmin",
		[AppRole.Executive]: "Executive",
		[AppRole.Mentor]: "Mentor",
		[AppRole.FamilyEmpowerment]: "FamilyEmpowerment",
		[AppRole.BusinessDevelopment]: "BusinessDevelopment",
		[AppRole.CenterDirector]: "CenterDirector",
		[AppRole.CenterStaff]: "CenterStaff",
		[AppRole.CenterTeacher]: "CenterTeacher",
	};

	export let WidgetTypeDisplayMap = {
		[WidgetType.Count]: "Count",
	};

	export class CommandNames {
		static Admin: string = "Admin"
		static AdminGetFeedbackSurveyLink: string = "AdminGetFeedbackSurveyLink"
		static AdminSaveFeedbackSurveyLink: string = "AdminSaveFeedbackSurveyLink"
		static AdminSaveFrequentQuestionList: string = "AdminSaveFrequentQuestionList"
		static AuditUserDocumentUsage: string = "AuditUserDocumentUsage"
		static BookmarkDocument: string = "BookmarkDocument"
		static CreateLocalUserAccount: string = "CreateLocalUserAccount"
		static CreateTestDummyCommandQueue: string = "CreateTestDummyCommandQueue"
		static DeleteCheckpoint: string = "DeleteCheckpoint"
		static DeleteExample: string = "DeleteExample"
		static DeleteFileLink: string = "DeleteFileLink"
		static DeleteProgram: string = "DeleteProgram"
		static DeleteRole: string = "DeleteRole"
		static EditFile: string = "EditFile"
		static ExecuteAllPendingQueues: string = "ExecuteAllPendingQueues"
		static ExecuteNextQueueEntry: string = "ExecuteNextQueueEntry"
		static ExecutePendingQueueEntries: string = "ExecutePendingQueueEntries"
		static ExportCheckpointsToCSV: string = "ExportCheckpointsToCSV"
		static GenerateAttendanceSheet: string = "GenerateAttendanceSheet"
		static GenerateDailyDefaultAttendanceSheets: string = "GenerateDailyDefaultAttendanceSheets"
		static GetAcceleratorDocumentClassificationList: string = "GetAcceleratorDocumentClassificationList"
		static GetAcceleratorDocumentList: string = "GetAcceleratorDocumentList"
		static GetApplicationSetting: string = "GetApplicationSetting"
		static GetAttendanceSheet: string = "GetAttendanceSheet"
		static GetCenter: string = "GetCenter"
		static GetCenterStatus: string = "GetCenterStatus"
		static GetCheckpoint: string = "GetCheckpoint"
		static GetChild: string = "GetChild"
		static GetClass: string = "GetClass"
		static GetCountry: string = "GetCountry"
		static GetCurrentUserProfile: string = "GetCurrentUserProfile"
		static GetDashboard: string = "GetDashboard"
		static GetExample: string = "GetExample"
		static GetException: string = "GetException"
		static GetFile: string = "GetFile"
		static GetFrequentlyAskedQuestionList: string = "GetFrequentlyAskedQuestionList"
		static GetGuardian: string = "GetGuardian"
		static GetMenu: string = "GetMenu"
		static GetMyProfile: string = "GetMyProfile"
		static GetProgram: string = "GetProgram"
		static GetQueueDetail: string = "GetQueueDetail"
		static GetRecentUserFiles: string = "GetRecentUserFiles"
		static GetReleaseNumber: string = "GetReleaseNumber"
		static GetRole: string = "GetRole"
		static GetRoles: string = "GetRoles"
		static GetTeacher: string = "GetTeacher"
		static GetUserAcceleratorAlerts: string = "GetUserAcceleratorAlerts"
		static GetUser: string = "GetUser"
		static GetUserWidgetSelections: string = "GetUserWidgetSelections"
		static ImportCheckpointsFromCSV: string = "ImportCheckpointsFromCSV"
		static IsUserAuthenticated: string = "IsUserAuthenticated"
		static Login: string = "Login"
		static Logout: string = "Logout"
		static LogTypeScriptError: string = "LogTypeScriptError"
		static OverdueAttendanceSheetCheck: string = "OverdueAttendanceSheetCheck"
		static PdfSearch: string = "PdfSearch"
		static ProcessEmailQueue: string = "ProcessEmailQueue"
		static QueueUserFile: string = "QueueUserFile"
		static RemoveUserFavoriteDocument: string = "RemoveUserFavoriteDocument"
		static SaveApplicationSetting: string = "SaveApplicationSetting"
		static SaveAttendanceSheet: string = "SaveAttendanceSheet"
		static SaveCenter: string = "SaveCenter"
		static SaveCheckpoint: string = "SaveCheckpoint"
		static SaveChild: string = "SaveChild"
		static SaveClass: string = "SaveClass"
		static SaveCountry: string = "SaveCountry"
		static SaveExample: string = "SaveExample"
		static SaveFile: string = "SaveFile"
		static SaveFileLink: string = "SaveFileLink"
		static SaveGuardian: string = "SaveGuardian"
		static SaveProgram: string = "SaveProgram"
		static SaveRole: string = "SaveRole"
		static SaveTeacher: string = "SaveTeacher"
		static SaveUser: string = "SaveUser"
		static SaveUserSignUp: string = "SaveUserSignUp"
		static SaveUserWidgetOrder: string = "SaveUserWidgetOrder"
		static SaveUserWidgetSelections: string = "SaveUserWidgetSelections"
		static SearchAttendance: string = "SearchAttendance"
		static SearchCenter: string = "SearchCenter"
		static SearchChild: string = "SearchChild"
		static SearchClass: string = "SearchClass"
		static SearchObjects: string = "SearchObjects"
		static SearchTeacher: string = "SearchTeacher"
		static SearchUserFiles: string = "SearchUserFiles"
		static SetUserAcceleratorAlertReadStatus: string = "SetUserAcceleratorAlertReadStatus"
		static TestDummy: string = "TestDummy"
		static TriggerServerException: string = "TriggerServerException"
		static UnusedSecurityCheckpointSearch: string = "UnusedSecurityCheckpointSearch"
	}

