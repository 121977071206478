import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardService {

  public favoriteData$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor() { }

}
