import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardComponent } from './components/dashboard/dashboard.component';

import { CanActivateGuard } from "./services";
import { LoginFailedComponent } from "./components/account/login-failed/login-failed.component";
import { GuardedComponent } from "./components/framework/guarded/guarded.component";

import {
  B2cLoginComponent,
  NotAuthorizedComponent,
  ApplicationSettingComponent,
  ApplicationSettingsComponent
} from "./components";
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FaqComponent } from './components/faq/faq.component';
import { AdminComponent } from './components/admin/admin.component';
import { AppRoutes } from './app-route.model';

const anonymousRoutes: Routes = [
  {
    path: "guarded",
    component: GuardedComponent,
    canActivate: [MsalGuard],
  },
  { path: "login", component: B2cLoginComponent },
  { path: "login-failed", component: LoginFailedComponent },
  { path: "not-authorized", component: NotAuthorizedComponent },
];

const authRoutes: Routes = [
  {
    path: "",
    canActivate: [CanActivateGuard],
    children: [
      { path: "", component: DashboardComponent },
      { path: AppRoutes.DASHBOARD.path, component: DashboardComponent },
      { path: AppRoutes.FAVORITES.path, component: FavoritesComponent },
      { path: AppRoutes.FAQ.path, component: FaqComponent },
      { path: AppRoutes.ADMIN.path, component: AdminComponent },
      //{ path: "error-logs", component: ErrorLogsComponent },
      //{ path: "error-log/:id", component: ErrorLogComponent },
      { path: "application-settings", component: ApplicationSettingsComponent },
      { path: "application-setting/:name", component: ApplicationSettingComponent },
      { path: "**", component: DashboardComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...anonymousRoutes, ...authRoutes])],
  exports: [RouterModule],
})
export class AppRoutingModule { }
