import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class UnsavedChangesDialogService {

  constructor() { }

  showDialog(): Promise<boolean> {
    var promise = new Promise<boolean>((resolve, reject) => {
      $('#unsavedChangesModal').modal('show');
      var discardButton = $('#unsavedChangesDiscard');
      var stayButton = $('#unsavedChangesModal .unsavedChangesStay');
      discardButton.click(() => {
        resolve(true);
      });
      stayButton.click(() => {
        resolve(false);
      });
    });
    return promise;
  }

}
