import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.scss']
})
export class ContentMenuComponent implements OnInit {

  public selectedMenuOption: string;
  public _menuOptions: any[] = [];
  @Input() set menuOptions(opts: any[]) {
    if (opts) {
      this._menuOptions = opts;
      if (this._menuOptions.length > 0) {
        this.setMenuOption(this._menuOptions[0].name);
      }
    }
  }

  public collapsed: boolean = false;

  @Output() optionChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() collapseMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public setMenuOption(optName: string): void {
    this.selectedMenuOption = optName;
    this.optionChanged.emit(optName);
  }

  public toggleMenu(): void {
    this.collapsed = !this.collapsed;
    this.collapseMenu.emit(this.collapsed);
    this.setIcon();
  }

  setIcon(): string {
    return !this.collapsed ? 'filter-icon-left' : 'filter-icon-right'
  }
}
