<div class="top-level-menu">
  <div class="menu-logo">
    <img src="./../../app/assets/images/logo.jpg" alt="Icon" />
  </div>
  <ul class="app-menu">
    <li>
      <a [routerLink]="appRoutes.DASHBOARD.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
        [matTooltip]="menuKey.Files | translate" matTooltipPosition="right"
        (click)="emitMenuItemChange(menuKey.Files)">
        <mat-icon svgIcon="menu-files"></mat-icon>
      </a>
    </li>
    <li>
      <a [routerLink]="appRoutes.FAVORITES.path" routerLinkActive="active" [matTooltip]="menuKey.Favorites | translate" matTooltipPosition="right"
        (click)="emitMenuItemChange(menuKey.Favorites)">
        <mat-icon svgIcon="menu-fav"></mat-icon>
      </a>
    </li>
    <li>
      <a [routerLink]="appRoutes.FAQ.path" routerLinkActive="active" [matTooltip]="menuKey.Faq | translate" matTooltipPosition="right"
        (click)="emitMenuItemChange(menuKey.Faq)">
        <mat-icon svgIcon="menu-faq"></mat-icon>
      </a>
    </li>
  </ul>
  <ul class="other-links">
    <li>
      <a href="https://collabforchildren.org/privacy-policy" target="_blank" [matTooltip]="menuKey.PrivacyPolicy | translate" matTooltipPosition="right">
        <mat-icon svgIcon="privacy-policy"></mat-icon>
      </a>
    </li>
  </ul>
</div>
