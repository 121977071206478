import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { UserLogin } from "./UserLogin";

@Injectable({ providedIn: "root" })
export class LoginService {
  // Observable string sources
  private emitUserLogin = new Subject<UserLogin>();
  private emitLoginMessage = new Subject<string>();

  // Observable string streams
  userLoginChangeEmitted$ = this.emitUserLogin.asObservable();
  loginMessageChangeEmitted$ = this.emitLoginMessage.asObservable();

  // Service message commands
  broadcastUserLogin(loginStatus: UserLogin) {
    this.emitUserLogin.next(loginStatus);
  }

  broadcastLoginMessage(message: string) {
    this.emitLoginMessage.next(message);
  }
}
