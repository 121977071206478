<h2 *ngIf="title">{{title}}</h2>
<div *ngIf="showSearch">
  <form name="searchForm" (ngSubmit)="load()">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8">
        <spk-text #searchInput [wrapped]="false" [group]="true" [groupButtonIcon]="'search'" [groupButtonText]="'Search'" (groupButtonClick)="load()" [placeholder]="'Search...'"
          [name]="'search'" [noId]="true" [(model)]="searchString">
          <button type="button" class="btn btn-outline-primary btn-sm" title="Export to CSV" *ngIf="csvExport && csvUrl" (click)="getcsv()"
            [attr.disabled]="csvButtonDisabled ? 'disabled' : null">
            <i class="fa fa-file-excel-o"></i> Export CSV
          </button>
          <button type="button" class="btn btn-outline-primary btn-sm" title="Export to PDF" *ngIf="pdfExport && pdfUrl" (click)="generatePdf()"
            [attr.disabled]="pdfButtonDisabled ? 'disabled' : null">
            <i class="fa fa-file-pdf-o"></i> Export PDF
          </button>
        </spk-text>
      </div>
      <div class="mr-2" *ngFor="let link of headerLinks">
        <span class="input-group-btn">
          <button type="button" class="btn btn-raised btn-sm {{link.class ? link.class : 'btn-light'}}" (click)="link.action()" [title]="link.title ? link.title : ''">
            <i [ngClass]="'fa fa-' + link.icon" aria-hidden="true"></i>
            {{link.text}}
          </button>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ng-content></ng-content>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!hasLoaded">
  <div *ngIf="!showSearch" class="fit"> Nothing has been loaded</div>
  <div *ngIf="showSearch" class="fit"> Search for results</div>
</div>
<div class="panel panel-default-dark" *ngIf="!cardView">
  <div class="table-responsive">
    <table class="table table-striped table-borderless table-hover table-sm">
      <thead>
        <tr>
          <th class="fit" *ngIf="leftLinks && leftLinks.length > 0"></th>
          <th *ngFor="let column of columns" class="getHeaderAlignment(column)">
            <a (click)="sortBy(column.SortName)" *ngIf="column.CanSort">
              {{column.DisplayName}}
              <span [hidden]="sortField != column.SortName">
                <i class="fa fa-angle-down" aria-hidden="true" *ngIf="sortDirection!='ASC'"></i>
                <i class="fa fa-angle-up" aria-hidden="true" *ngIf="sortDirection!='DESC'"></i>
              </span>
            </a>
            <a *ngIf="!column.CanSort">{{column.DisplayName}}</a>
          </th>
          <th class="fit" *ngIf="rightLinks && rightLinks.length > 0"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows; let i = index" (mouseenter)="setMousedOverRow(row)" (mouseleave)="unsetMousedOverRow()" class="{{rowCss(i)}} {{columnCss(row)}}">
          <td class="fit" *ngIf="leftLinks && leftLinks.length > 0">
            <div class="button-container">
              <span class="input-group-btn" *ngFor="let link of leftLinks">
                <span *ngIf="(link.isVisible != null && link.isVisible(row)) || !link.isVisible">
                  <button *ngIf="!shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" (click)="link.action != null && link.action(row)"
                    class="grid-btn btn btn-sm btn-raised {{link.class ? link.class : 'btn-light'}}">
                    <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
                  </button>
                  <button *ngIf="shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" [reallyClick]="link.reallyClickMessage"
                    [reallyClickAction]="link.reallyClickAction" [reallyClickCaller]="link.reallyClickCaller" [reallyClickArguments]="[row]" [reallyClickButtons]="link.reallyClickButtons"
                    class="grid-btn btn btn-sm btn-raised {{link.class ? link.class : 'btn-light'}}">
                    <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
                  </button>
                </span>
              </span>
            </div>
          </td>
          <td *ngFor="let column of columns">
            <span *ngIf="cellIsStringFormat(column.Format)">{{format(row[column.ColumnName], column.Format)}}</span>
            <div *ngIf="column.Format == 'OnTextClick'">
              <a href="javascript:undefined" (click)="callClickAction(column.ColumnName, row)">{{format(row[column.ColumnName], column.Format)}}</a>
            </div>
            <div *ngIf="column.Format == 'Custom'">
              <div [innerHTML]="getCustomFormat(column.ColumnName, row[column.ColumnName])"></div>
            </div>
            <div *ngIf="column.Format == 'NavigationLink'">
              <a [routerLink]="getColumnRouterLink(row, column.ColumnName)" [queryParams]="getColumnQueryParams(row, column.ColumnName)">{{format(row[column.ColumnName], column.Format)}}</a>
            </div>
            <div *ngIf="column.Format.indexOf('Boolean') !== -1" class="text-center">
              <i *ngIf="row[column.ColumnName]" class="fa fa-check text-success"></i>
              <i *ngIf="!row[column.ColumnName] && column.Format == 'CheckXBoolean'" class="fa fa-times text-danger"></i>
            </div>
            <div *ngIf="column.Format == 'CheckBox'">
              <spk-checkbox class="pull-left" [name]="column.ColumnName + i" [disabled]="true" [(model)]="row[column.ColumnName]" [wrapped]="false"></spk-checkbox>
            </div>
            <div *ngIf="column.Format == 'Image'" class="text-center">
              <img class="search-grid-image" [src]="format(row[column.ColumnName], column.Format)" />
            </div>
          </td>
          <td class="fit button-container" *ngIf="rightLinks && rightLinks.length > 0">
            <div class="button-container">
              <span class="input-group-btn " *ngFor="let link of rightLinks">
                <button *ngIf="!shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" (click)="link.action != null && link.action(row)"
                  class="btn btn-sm btn-raised {{link.class ? link.class : 'btn-light'}}">
                  <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
                </button>
                <button *ngIf="shouldUseReallyClick(link)" [title]="link.title ? link.title : ''" [reallyClick]="link.reallyClickMessage" [reallyClickAction]="link.reallyClickAction"
                  [reallyClickCaller]="link.reallyClickCaller" [reallyClickArguments]="[row]" [reallyClickButtons]="link.reallyClickButtons"
                  class="btn btn-sm btn-raised {{link.class ? link.class : 'btn-light'}}">
                  <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
                </button>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <spk-pager (change)="load()" [(pagedResult)]="pagedResult" [hasLoaded]="hasLoaded"></spk-pager>
</div>
<div *ngIf="cardView">
  <div class="card search-grid-card" *ngFor="let row of rows">
    <div class="card-body">
      <p *ngFor="let column of columns"><b>{{column.DisplayName}}:</b> {{row[column.ColumnName]}}</p>
      <div class="row">
        <div class="col text-center" *ngFor="let link of leftLinks">
          <span class="input-group-btn">
            <button *ngIf="!shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" (click)="link.action != null && link.action(row)"
              class="grid-btn btn  btn-raised {{link.class ? link.class : 'btn-light'}} btn-lg">
              <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
            </button>
            <button *ngIf="shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" [reallyClick]="link.reallyClickMessage"
              [reallyClickAction]="link.reallyClickAction" [reallyClickCaller]="link.reallyClickCaller" [reallyClickArguments]="[row]" [reallyClickButtons]="link.reallyClickButtons"
              class="grid-btn btn btn-lg btn-raised {{link.class ? link.class : 'btn-light'}}">
              <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
            </button>
          </span>
        </div>
        <div class="col text-center" *ngFor="let link of rightLinks">
          <span class="input-group-btn">
            <button *ngIf="!shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" (click)="link.action != null && link.action(row)"
              class="btn btn-lg btn-raised {{link.class ? link.class : 'btn-light'}}">
              <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
            </button>
            <button *ngIf="shouldUseReallyClick(link)" type="button" [title]="link.title ? link.title : ''" [reallyClick]="link.reallyClickMessage"
              [reallyClickAction]="link.reallyClickAction" [reallyClickCaller]="link.reallyClickCaller" [reallyClickArguments]="[row]" [reallyClickButtons]="link.reallyClickButtons"
              class="btn btn-lg btn-raised {{link.class ? link.class : 'btn-light'}}">
              <i *ngIf="link.icon" [ngClass]="'fa fa-fw fa-'+link.icon"></i>{{link.text}}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <spk-pager (change)="load()" [(pagedResult)]="pagedResult" [hasLoaded]="hasLoaded"></spk-pager>
</div>