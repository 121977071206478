import { Injectable } from "@angular/core";

import { Subject } from "rxjs";
import { MenuKey } from "./menu-key.enum";

@Injectable({ providedIn: "root" })
export class MenuService {
  private readonly menuItemSubject$ = new Subject<MenuKey>();

  menuItemChange$ = this.menuItemSubject$.asObservable();

  constructor() {}

  emitMenuItemChange(menuKey: MenuKey) {
    this.menuItemSubject$.next(menuKey);
  }
}
