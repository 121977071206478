import { Component, OnInit } from '@angular/core';

import { AppRoutes } from '../../../app-route.model';
import { MenuKey } from './menu-key.enum';
import { MenuService } from './menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  menuKey = MenuKey;
  appRoutes = AppRoutes;
  
  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
  }

  emitMenuItemChange(menuKey: MenuKey) {
    this.menuService.emitMenuItemChange(menuKey);
  }
}
