
<div class="faq-app">
  <div class="faq-list">
    <div class="faq-item" *ngFor="let faq  of faqList; let odd=odd;" [class.odd]="odd">
      <div class="title-text">
        <div>Q.</div>
      </div>
      <div>{{faq.Question}}</div>
      <div class="title-text">A.</div>
      <div>{{faq.Answer}}</div>
    </div>
  </div>
</div>
