import { Injectable } from "@angular/core";
import * as signalR from "@aspnet/signalr";

@Injectable({
  providedIn: "root"
})
export class SignalRService {

  public orderBookStatuses: any;

  private hubConnection: signalR.HubConnection

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/signalr-hub")
      .build();

    this.hubConnection
      .start()
      .then(() => console.log("Connection started"))
      .catch(err => console.log("Error while starting connection: " + err))
  }

  public addAllListeners = () => {
    this.hubConnection.on("OnSomeUpdate", (data: any) => {
      this.orderBookStatuses = data;
      console.log(data);
    });
  }
}
