import { Component, OnDestroy, OnInit } from '@angular/core';

import { FrequentQuestionDto, GetFrequentlyAskedQuestionListCommandRequest } from '../../data/CommandServiceDtos.generated';
import { PageContainerService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../translate/language.model';
import * as moment from 'moment';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { AppRoutes } from '../../app-route.model';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  public faqList: FrequentQuestionDto[] = [];
  currentLanguage = Languages.English;

  constructor(
    private pcs: PageContainerService,
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb([
      AppRoutes.FAQ
    ]);

    this.currentLanguage = Languages.getByCode(this.translate.currentLang);
    this.getFAQ();

    this.translate.onLangChange.subscribe(data => {
      this.currentLanguage = Languages.getByCode(data.lang);
      this.getFAQ();
    });
  }

  ngOnDestroy() {
    this.breadcrumbService.clear();
  }

  async getFAQ(): Promise<any> {
    const payload: GetFrequentlyAskedQuestionListCommandRequest = { Language: this.currentLanguage?.name };
    await this.pcs.apiService.GetFrequentlyAskedQuestionList(payload).then(response => {
      console.log('FAQ: ', response.FrequentlyAskedQuestions);
      this.faqList = response.FrequentlyAskedQuestions;
    });
  }

  public setDate(ValidFrom): string {
    return moment(ValidFrom).format('MM/DD/YYYY');
  }

}
