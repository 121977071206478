<nav class="app-breadcrumb ml-4" aria-label="breadcrumb">
  <a class="nav-home-icon">
    <i class="fa fa-angle-right"></i>
  </a>

  <ol class="breadcrumb">
    <ng-container *ngFor="let item of breadcrumbItems$ | async as items; last as isLast">
      <li *ngIf="!isLast" [@fadeIn] class="breadcrumb-item">
        <a [routerLink]="[item.path]" (click)="emitBreadcrumbItemChange(item.name)">{{ item.name | translate }}</a>
      </li>
      <li *ngIf="isLast" [@fadeIn] class="breadcrumb-item active" aria-current="page">
        {{ item.name | translate }}
      </li>
    </ng-container>
  </ol>
</nav>
