import { MenuKey } from "./components/dashboard/menu/menu-key.enum";

export interface AppRoute { name: string; path: string };

export const AppRoutes: { [key: string]: AppRoute} = {
  DASHBOARD: {
    name: MenuKey.Files,
    path: 'dashboard'
  },
  FAVORITES: {
    name: MenuKey.Favorites,
    path: 'favorites'
  },
  FAQ: {
    name: MenuKey.Faq,
    path: 'faq'
  },
  ADMIN: {
    name: MenuKey.Admin,
    path: 'admin'
  },
}
