<div class="header-component">

  <breadcrumb></breadcrumb>

  <div class="menu-content">
    <div style="margin-bottom: 5px">
      <span>ENG</span>
      <mat-slide-toggle class="mx-2" color="primary" (change)="toggleLanguage($event)"></mat-slide-toggle>
      <span>ESP</span>
    </div>
    <div *ngIf="userIsBasAdmin">
      <button mat-button (click)="goToRoute()">{{'ADMIN' | translate}}</button>
    </div>
    <div>
      <button mat-button [matMenuTriggerFor]="training">{{'TRAINING' | translate}}</button>
      <mat-menu #training="matMenu">
        <a href="https://collabforchildren.org/resources-for-educators/business-accelerator/2022-business-accelerator-schedule/" target="_blank">
          <button mat-menu-item>{{'TRAINING_CALENDAR' | translate}}</button>
        </a>
        <a href="https://sites.google.com/tamu.edu/informationsitebusinesstrainin/home" target="_blank">
          <button mat-menu-item>{{'CANVAS_MODULE' | translate}}</button>
        </a>
      </mat-menu>
      <!--
    <button mat-button>{{'FILES' | translate}}</button>
    -->
      <!--
    <div mat-menu #files="matMenu">
       <button mat-menu-item>Item 1</button>
       <button mat-menu-item>Item 2</button>
     </div>
    -->
    </div>

    <div>
      <button mat-button (click)="goToFAQ()">{{'HELP' | translate}}</button>
    </div>

    <div>
      <button mat-fab style="background: #7158be;" mat-button [matMenuTriggerFor]="menu">{{ userInitials }}</button>
      <mat-menu #menu="matMenu" style="width: 200px;">

        <div style="display: flex; flex-direction: column">
          <span style="color: #7158be; font-size: large">{{ userFullName }}</span>
          <div *ngIf="userIsBasAdmin" id="divShowAdmin">
            <span style="margin-bottom: 10px;">{{'ADMIN_USER' | translate}}</span>
          </div>
        </div>
        <hr>
        <button mat-button (click)="logOutUser()">{{'LOGOUT' | translate}}</button>
      </mat-menu>
    </div>

  </div>
</div>
