import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../data/ApiService.generated';
import { LoginCommandRequest } from "../../../data/CommandServiceDtos.generated";
import { ClientSessionService, NotificationService } from '../../../services';

@Component({
  selector: 'b2c-login',
  template: '',
})
export class B2cLoginComponent implements OnInit, OnDestroy {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientSessionService: ClientSessionService,
    private apiService: ApiService,
    public notificationService: NotificationService) {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log("=================== Start creating the user session :: EventType.LOGIN_SUCCESS ================== ");
        this.checkAndSetActiveAccount();
        const authResult = result.payload as AuthenticationResult;
        const LoginCommandRequest = {
          Username: authResult.account.username,
          ServiceToken: authResult.idToken,
        }

        this.apiService
          .Login(LoginCommandRequest as LoginCommandRequest)
          .then((loginCommandResponse) => {
            console.log("=================== Login command response ================== UserName: ", loginCommandResponse?.UserInfo?.UserName);
            if (loginCommandResponse?.IsAuthenticated) {
              this.clientSessionService.loginUser(loginCommandResponse.UserInfo);
              this.router.navigate(['dashboard']);
              this.notificationService.info("You are now logged in.", null, { timeOut: 8000 });
            } else {
              this.handleLoginRequestFailure(loginCommandResponse?.FailedAuthMessage ?? "loginCommandResponse is NULL");
            }
          });
      });
  }

  ngOnInit() {
    this.checkAndSetActiveAccount();

    if (this.authService.instance.getActiveAccount() != null) {

      const accessTokenRequest = {
        scopes: ["openid"],
        account: this.authService.instance.getActiveAccount()
      }

      this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
        authenticationResult => {
          const LoginCommandRequest = {
            Username: authenticationResult.account.username,
            ServiceToken: authenticationResult.idToken,
          }
    
          this.apiService
            .Login(LoginCommandRequest as LoginCommandRequest)
            .then((loginCommandResponse) => {
              if (loginCommandResponse.IsAuthenticated) {
                console.log("BAS B2C-Login Component: User is authenticated from Login command (" + loginCommandResponse.UserInfo.UserName + ")");
                this.clientSessionService.loginUser(loginCommandResponse.UserInfo);
                this.router.navigate(['dashboard']);
                this.notificationService.info("Your session has been refreshed.", null, { timeOut: 8000 });
              } else {
                this.handleLoginRequestFailure(loginCommandResponse.FailedAuthMessage);
              }
            });
        },
        error => {
          window.localStorage.clear()
          this.authService.loginRedirect();
        });

    } else {
      if (this.activatedRoute.snapshot.fragment?.includes('error=')) {
        location.replace(environment.redirectUri);
      } else if (!this.activatedRoute.snapshot.fragment) {
        setTimeout(() => this.authService.loginRedirect(), 200); 
      }
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private checkAndSetActiveAccount(){
    let activeAccount = this.authService.instance.getActiveAccount();

    const accounts = this.authService.instance.getAllAccounts();
    if (!activeAccount && accounts.length > 0) {
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private handleLoginRequestFailure(errorMessage: string) {
    this.notificationService.danger(errorMessage, null, { timeOut: 8000, extendedTimeOut: 5000 });
    setTimeout(() => {
      this.authService.logoutRedirect();
    }, 7500);
    this.logError(errorMessage);
  }

  private logError(errorMessage: string) {
    var request = {
      Message : errorMessage,
      Type : 'TypeScript.B2cLoginComponent',
      Url : this.router.url,
      Stack: null
    };

    this.apiService.LogTypeScriptError(request, false);
  }
}
