import { DateTime } from 'luxon';
import { DateDto } from "../../data/CommandServiceDtos.generated";

export class DateDtoUtility {

  public static yearsDiff(mostRecentDate: DateDto, olderDate: DateDto): number {
    var newer = this.getDateTime(mostRecentDate);
    var older = this.getDateTime(olderDate);
    var result = newer.diff(older, 'years').toObject();

    return result.years;
  }

  public static monthsDiff(mostRecentDate: DateDto, olderDate: DateDto): number {
    var newer = this.getDateTime(mostRecentDate);
    var older = this.getDateTime(olderDate);
    var result = newer.diff(older, 'months').toObject();

    return result.months;
  }

  public static createDateDtoFromString(date: string): DateDto {
    var dateDto: DateDto;
    if (this.isDateStringValid(date)) {
      var dateParts = date.split('/');
      dateDto = {
        Month: <any>dateParts[0],
        Day: <any>dateParts[1],
        Year: <any>dateParts[2]
      };

    }
    else {
      dateDto = null;
    }
    return dateDto;
  }

  public static createDateDtoFromDateTime(dateTime: DateTime): DateDto {
    var result = this.createDateDtoFromParts(dateTime.year, dateTime.month, dateTime.day);
    return result;
  }

  public static createDateDtoFromParts(year: number, month: number, day: number): DateDto {
    var result: DateDto = {
      Day: day,
      Month: month,
      Year: year
    };
    return result;
  }

  public static createDateDtoFromJsDate(date: Date): DateDto {
    date = new Date(date);
    var result: DateDto = {
      Day: date.getDate(),
      Month: date.getMonth() + 1,
      Year: date.getFullYear(),
    };

    return result;
  }

  public static isDateStringValid(date: string) {
    if (date == undefined || date == null || date == "") {
      return false;
    }
    var dateParts = date.split('/');
    if (dateParts.length != 3) {
      return false;
    }
    if (isNaN(<any>dateParts[0]) || isNaN(<any>dateParts[1]) || isNaN(<any>dateParts[2])) {
      return false;
    }
    return true;
  }

  public static isDateDtoValid(date: DateDto) {
    if (date == undefined || date == null) {
      return false;
    }
    if (isNaN(date.Year) || isNaN(date.Month) || isNaN(date.Day)) {
      return false;
    }
    return true;
  }

  public static getDateTime(date: DateDto): DateTime {
    var formattedDate = this.formatDateStringForDateTime(date);
    return DateTime.fromISO(formattedDate);
  }

  public static formatDateStringForDateTime(date: DateDto): string {
    var result = "";
    if (this.isDateDtoValid(date)) {
      result = `${date.Year.toString().padStart(4, "0")}-${date.Month.toString().padStart(2, "0")}-${date.Day.toString().padStart(2, "0")}T00:00:00`;
    } else {
      result = "";
    }
    return result;
  }

  public static formatDateStringMMDDYYYY(date: DateDto): string {
    var result = "";
    if (this.isDateDtoValid(date)) {
      result = `${date.Month.toString().padStart(2, '0')}/${date.Day.toString().padStart(2, '0')}/${date.Year.toString()}`;
    } else {
      result = "";
    }
    return result;
  }

  public static today(): DateDto {
    var todayDateTime = DateTime.now();
    var result = this.createDateDtoFromDateTime(todayDateTime);
    return result;
  }

  public static datesAreTheSame(date1: DateDto, date2: DateDto): boolean {
    var result = false;
    if (date1 && date2) {
      var result = date1.Year == date2.Year && date1.Month == date2.Month && date1.Day == date2.Day;
    }
    return result;
  }

  public static addDays(date: DateDto, days: number): DateDto {
    var dateTime = this.getDateTime(date);
    var newDateTime = dateTime.plus({ days: days });
    var result = this.createDateDtoFromDateTime(newDateTime);
    return result;
  }

  public static compareDates(date1: DateDto, date2: DateDto): CompareResult {
    var dateTime1 = this.getDateTime(date1);
    var dateTime2 = this.getDateTime(date2);
    if (dateTime1 > dateTime2) {
      return CompareResult.GreaterThan;
    } else if (dateTime1 < dateTime2) {
      return CompareResult.LessThan;
    } else {
      return CompareResult.Same;
    }
  }
}

export enum CompareResult {
  Same = 1,
  GreaterThan = 2,
  LessThan = 3
}
