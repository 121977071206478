<div class="bas-app">

  <div class="bas-menu">
    <app-menu></app-menu>
  </div>

  <main class="bas-main">
    <div class="bas-header">
      <app-header></app-header>
    </div>

    <div style="height: 100%; overflow: hidden;">
      <router-outlet></router-outlet>
    </div>
  </main>

  <div class="busy-overlay" *ngIf="isBusy"></div>
  <div class="busy-indicator" tabindex="-1" role="dialog" id="busy-indicator" data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="busy-indicator-backdrop" id="busy-indicator-backdrop"></div>
</div>
