import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { takeUntil } from "rxjs/operators";
import { Subject } from "../../../ng";
import {
  AuthenticationType,
  AuthenticationTypeDisplayMap,
  UserInfoDto
} from '../../../data/CommandServiceDtos.generated';
import { ObjectUtility } from "../../../utilities/objects/object.utility";
import { PageContainerService } from '../../../services';
import { CanComponentDeactivate } from '../../../services/can-deactivate-guard.service';
import { ControllerForViewBase } from '../../framework/controller-for-view-base';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../translate/language.model';
import { MsalService } from '@azure/msal-angular';
import { LoginService } from "../../account/login/login.service";

const config = require("../../../../app-config.json");

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ControllerForViewBase implements OnInit, OnDestroy, CanComponentDeactivate {
  private readonly _destroying$ = new Subject<void>();
  private readonly BAS_ADMIN = 'BAS Admin';

  userInfo: UserInfoDto;
  userFullName: string = "";
  userInitials: string = "";
  userIsBasAdmin: boolean = false;
  screenMessage: string = "Login to Collab BAS....";

  constructor(
    public pcs: PageContainerService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private msalService: MsalService) {
    super(pcs);
  }

  ngOnInit(): void {
    this.pcs.clientSessionService.userInfoChanges$
      .pipe(takeUntil(this._destroying$))
      .subscribe(user => {
        if (user.Id > 0) {
          this.setCurrentUserInfo(user);
        } else {
          this.userInfo = null;
        }
      });

    this.loginService.broadcastLoginMessage(this.screenMessage);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  canDeactivate(): Promise<boolean> {
    var promise = Promise.resolve(true);
    return promise;
  }

  toggleLanguage(event: MatSlideToggleChange) {
    if (event.checked) {
      this.translate.use(Languages.Spanish.code);
    } else {
      this.translate.use(Languages.English.code);
    }
  }

  goToRoute() {
    this.router.navigate(['admin'], { relativeTo: this.route });
  }

  goToFAQ() {
    this.router.navigate(['faq'], { relativeTo: this.route });
  }

  logOutUser() {
    this.msalService.logoutRedirect();
    this.pcs.apiService
      .Logout({}, true)
      .then((response) => this.onLogoutSucceeded());

    this.loginService.broadcastLoginMessage("");
                    
  }
  
  onLogoutSucceeded() {
    this.pcs.clientSessionService.reset();
    if (config.security.authenticationType === AuthenticationTypeDisplayMap[AuthenticationType.LoginActiveDirectory] || config.security.authenticationType === AuthenticationTypeDisplayMap[AuthenticationType.Database]) {
      this.pcs.router.navigate(["login"]);
    } else if (config.security.authenticationType === AuthenticationTypeDisplayMap[AuthenticationType.AzureActiveDirectory]) {
      var guid = ObjectUtility.generateUUID();
      var encodedRedirect = encodeURIComponent(config.security.redirectUri);
      var redirectUri = "https://login.microsoftonline.com/336411f2-bdde-4ad6-bc95-f51feae5a2cf/oauth2/v2.0/authorize?response_type=code+id_token&redirect_uri=" +
        encodedRedirect + "&client_id=" + config.security.clientId + "&scope=openid+profile+email&response_mode=form_post&nonce=" + guid + "&state=redir%3D%252Flogin&sso_reload=true";

      var logoutUri = "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" + encodeURIComponent(redirectUri);
      window.location.href = logoutUri;
    }
  }

  private setCurrentUserInfo(currentUserInfo: UserInfoDto) {
    this.userInfo = {... currentUserInfo};
    this.userFullName = `${this.userInfo.FirstName} ${this.userInfo.LastName}`;

    const firstNameInitial = this.userInfo.FirstName?.charAt(0);
    const lastNameInitial = this.userInfo.LastName?.charAt(0);
    this.userInitials = `${firstNameInitial}${lastNameInitial}`;

    this.userIsBasAdmin = this.userInfo.Roles.some(o => o.Text === this.BAS_ADMIN)
  }
}
