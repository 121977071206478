import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { PageContainerService } from '../../services/page-container.service';
import {
  GetAcceleratorDocumentListCommandRequest,
  GetAcceleratorDocumentListCommandResponse,
  AcceleratorDocumentDto,
  GetAcceleratorDocumentClassificationListCommandRequest,
  GetAcceleratorDocumentClassificationListCommandResponse,
  ClassificationDto,
  BookmarkDocumentCommandRequest,
  GetUserAcceleratorAlertsCommandRequest,
  UserAlertDto,
  FrequentQuestionDto,
  SetUserAcceleratorAlertReadStatusCommandRequest,
  AuditUserDocumentUsageCommandRequest,
} from '../../data/CommandServiceDtos.generated';
import { ControllerForViewBase } from '../framework/controller-for-view-base';
import * as moment from 'moment';
import { DashboardService } from './services/dashboard.service';
import { Languages } from '../../translate/language.model';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { MenuService } from './menu/menu.service';
import { MenuKey } from './menu/menu-key.enum';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { AppRoutes } from '../../app-route.model';
import { ContentMenuItem } from './content-menu/content-menu-item.model';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent extends ControllerForViewBase implements OnInit, AfterViewInit, OnDestroy {
  private readonly _destroying$ = new Subject();

  @ViewChild(MatSort) sort: MatSort;

  documentCategories: ClassificationDto[] = [];
  menuOptions: ContentMenuItem[] = [];
  menuCollapsed: boolean = false;
  alertList: UserAlertDto[] = [];
  faqList: FrequentQuestionDto[] = [];

  model: AcceleratorDocumentDto[] = [];
  tableData: any[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'date', 'category', 'downloads'];
  dataSource = new MatTableDataSource<any[]>([]);
  searchBoxControl = new FormControl();
  
  classifications: ClassificationDto[] = new Array<ClassificationDto>();
  currentCategory: ContentMenuItem;
  currentLanguage = Languages.English;

  constructor(
    private dashBoardSrv: DashboardService,
    private _liveAnnouncer: LiveAnnouncer,
    private translate: TranslateService,
    private menuService: MenuService,
    private breadcrumbService: BreadcrumbService,
    pcs: PageContainerService) {
    super(pcs);
  }

  ngOnInit() {
    this.currentLanguage = Languages.getByCode(this.translate.currentLang);
    this.getAcceleratorClassificationsSync();
    
    this.translate.onLangChange
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        this.currentLanguage = Languages.getByCode(data.lang);
        this.searchBoxControl.setValue(null);
        this.breadcrumbService.clear();
        this.getAcceleratorClassificationsSync();
      });

    this.searchBoxControl.valueChanges
      .pipe(
        takeUntil(this._destroying$),
        debounceTime(500)
      )
      .subscribe(value => {
        if (value) {
          this.applyFilter(value);
          this.menuOptions = [];
          this.currentCategory = null;
          this.setBreadcrumb();
        } else {
          this.menuOptions = this.documentCategories.map(c => this.getMenuItem(c));
        }
      });

      merge(
        this.menuService.menuItemChange$,
        this.breadcrumbService.breadcrumbItemChange$,
      )
      .pipe(
        takeUntil(this._destroying$),
        filter((menuKey: MenuKey) => menuKey === MenuKey.Files),
      )
      .subscribe(menuKey => {
        const searchValue = this.searchBoxControl.value as string;
        if (searchValue?.length > 0) {
          this.searchBoxControl.setValue(null);
        } else {
          this.menuOptions = this.documentCategories.map(c => this.getMenuItem(c));
        }
      });
  }
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.breadcrumbService.clear();
    this._destroying$.next();
    this._destroying$.complete();
  }

  getAcceleratorClassificationsSync() {
    const payload: GetAcceleratorDocumentClassificationListCommandRequest = {
      Language: this.currentLanguage?.name
    };

    this.pcs.apiService.GetAcceleratorDocumentClassificationList(payload, true, false)
      .then(async responseClassList => {
        await this.getDocumentListSync();
        this.processDocumentClassificationList(responseClassList);
      });
  }

  processDocumentClassificationList(response: GetAcceleratorDocumentClassificationListCommandResponse) {
    this.documentCategories = response.Classifications;
    this.menuOptions = this.documentCategories.map(c => this.getMenuItem(c));
  }

  async getDocumentListSync() {
    const payload: GetAcceleratorDocumentListCommandRequest = { 
      ClassificationName: '', 
      Language: this.currentLanguage?.name,
    };

    const response = await this.pcs.apiService.GetAcceleratorDocumentList(payload, true, false);
    this.processDocumentListResponse(response);
    setTimeout(() => {
      this.getAlerts();
    }, 0);
  }

  processDocumentListResponse(response: GetAcceleratorDocumentListCommandResponse) {
    this.model = response.Documents.map(d => d);

    this.tableData = this.model.map((m, idx) => {
      const data = {
        id: m.Id,
        position: idx,
        type: m.FileExtension.toLowerCase(),
        name: m.Name,
        weight: 1.0079,
        description: m.Description,
        date: moment(m.LastUpdated).format('MM/DD/YYYY'),
        dateUTC: moment(m.LastUpdated).format('MM/DD/YYYY'),
        categoryId: m.ClassificationId,
        category: m.ClassificationDisplay,
        downloads: '',
        userFavorite: m.UserFavorite,
        englishVersionURL: m.EnglishVersionURL,
        spanishVersionURL: m.SpanishVersionURL,
        data: m
      }

      return data;
    });

    this.buildFavoritesList();
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.data = this.tableData.filter(o => 
      this.containsSubstring(o.name, filterValue) ||
      this.containsSubstring(o.description, filterValue) ||
      this.containsSubstring(o.category, filterValue) ||
      this.containsSubstring(o.date, filterValue)
    );
  }

  documentCategoryChanged(categoryName: string) {
    if (this.currentCategory?.name !== categoryName) {
      this.setBreadcrumb(categoryName);
    }

    this.currentCategory = this.menuOptions.find(o => o.name === categoryName);

    const categoryDocuments = this.tableData.filter(o => o.categoryId === this.currentCategory?.id);
    this.dataSource.data = [...categoryDocuments];
  }

  setFileIconType(fileType: string): string {
    return fileType;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  downloadFile(data: any): void {
    const payload: AuditUserDocumentUsageCommandRequest = {
      Language: "",
      DocumentID: 0,
      DocumentUrl: data
    };
    this.pcs.apiService.AuditUserDocumentUsage(payload);

    window.open(data, "_blank")
  }

  setMenuState(collapsed: boolean): void {
    this.menuCollapsed = collapsed;
  }

  async setFavoriteState(doc): Promise<any> {

    const payload: BookmarkDocumentCommandRequest = new BookmarkDocumentCommandRequest();
    doc.data.UserFavorite = !doc.userFavorite;

    payload.BookmarkedDocuments = [doc.data];

    await this.pcs.apiService.BookmarkDocument(payload).then(response => {
      doc.userFavorite = !doc.userFavorite;
      this.buildFavoritesList();
    });
  }

  async getAlerts(): Promise<any> {
    const payload: GetUserAcceleratorAlertsCommandRequest = {
      UnreadMessagesOnly: true,
      Language: this.currentLanguage?.name ?? '',
    };

    await this.pcs.apiService.GetUserAcceleratorAlerts(payload)
      .then(response => {
        this.alertList = response.UserAlerts;
      });
  }

  async closeAlert(alert: UserAlertDto): Promise<any> {
    const payload: SetUserAcceleratorAlertReadStatusCommandRequest = {
      AlertID: alert.AlertID,
      MarkAsRead: true
    };

    await this.pcs.apiService.SetUserAcceleratorAlertReadStatus(payload)
      .then(response => {
        this.getAlerts();
      });
  }

  private buildFavoritesList() {
    let favoriteList = this.tableData.filter(o => o.userFavorite);
    console.log('Favorites: ', favoriteList);
    this.dashBoardSrv.favoriteData$.next([...favoriteList]);
  }

  private containsSubstring(string: string, substring: string): boolean {
    const normalizeString = string.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const normalizeSubstring = substring.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    const result = normalizeString.includes(normalizeSubstring);

    return result;
  }

  private getMenuItem(docCategory: ClassificationDto): ContentMenuItem  {
    return { id: docCategory.Id, icon: docCategory.IconImageName, name: docCategory.Name };
  }

  private setBreadcrumb(documentCategory: string = undefined) {
    const breadcrumb = [AppRoutes.DASHBOARD];
    if (documentCategory) {
      breadcrumb.push({
        name: documentCategory,
        path: ''
      });
    }

    this.breadcrumbService.setBreadcrumb(breadcrumb);
  }
}
