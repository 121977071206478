<div class="content-menu">

  <div class="menu-title">
    <div class="title" [style.display]="collapsed ? 'none' : 'block'">
      {{'FOLDER' | translate}}
    </div>
    <div class="icon">
      <mat-icon style="cursor: pointer" [svgIcon]="setIcon()" (click)="toggleMenu()"></mat-icon>
    </div>
  </div>

  <hr />

  <div class="menu-items">
    <div class="menu-item" [class.active]="selectedMenuOption === item.name" *ngFor="let item of _menuOptions" (click)="setMenuOption(item.name)">
      <mat-icon [matTooltip]="item.name" [svgIcon]="item.icon"></mat-icon>
      <div class="item-name" [style.display]="collapsed ? 'none' : 'block'">
        <span>
          {{item.name}}
        </span>
      </div>
    </div>
  </div>

</div>
