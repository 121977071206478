import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppRoutes } from '../../app-route.model';
import { MenuKey } from '../dashboard/menu/menu-key.enum';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity:0 }),
        animate(500, style({ opacity:1 })) 
      ])
    ])
  ]
})
export class BreadcrumbComponent {

  breadcrumbItems$ = this.breadcrumbService.breadcrumbChanges$;

  appRoutes = AppRoutes;

  constructor(private breadcrumbService: BreadcrumbService) {}

  emitBreadcrumbItemChange(menuKey: string) {
    this.breadcrumbService.emitBreadcrumbItemChange(menuKey as MenuKey);
  }
}
