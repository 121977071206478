import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';


import {
  HttpClientModule,
  HttpClientXsrfModule
} from '@angular/common/http';

import { ReactiveFormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';

import { HrefToRouterLinkDirective } from './directives/href-to-router-link.directive';

import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";

import {
  AppComponent,
  B2cLoginComponent,
  LoginComponent,
  GuardedComponent,
  LoginFailedComponent,
  ApplicationSettingComponent,
  ApplicationSettingsComponent,
  CheckpointsComponent,
  NotAuthorizedComponent,
  RoleComponent,
  RolesComponent,
  UserComponent,
  UsersComponent,
  ProfileComponent,
  ErrorLogComponent,
  ErrorLogsComponent,
  SpkSearchGridComponent,
  MenuComponent,
  HeaderComponent,
  BreadcrumbComponent,
} from './components';


import { DashboardModule } from "./components/dashboard/dashboard.module";

import {
  ActionLinksPanelService,
  BusyService,
  CanDeactivateGuard,
  ClientSessionService,
  ExecuteCommandService,
  PageContainerService,
  ReallyClickService,
  ValidationResultsService,
  UnsavedChangesDialogService,
  NotificationService,
  SearchGridService,
  BrowserNavigateService,
  TabService,
  SearchGridCustomFilterService,
  CanActivateGuard,
  UserFileService,
} from "./services";

import {
  Notifier,
  ToastrNotifier,
  DateDtoPipe,
  StringSpacerPipe,
  UppercasePipe,
  NegativeParenthesesPipe,
  B2CAuthConfig
} from './utilities';


//import { MSALGuardConfigFactory, MSALInterceptorConfigFactory } from './utilities/msal/msal-utility';

import { GlobalExceptionHandler } from './utilities/exceptions/GlobalExceptionHandler';
import { ReallyClickDirective } from './directives';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './data/ApiService.generated';
import { PercentPipe, JsonPipe } from '@angular/common';
import { NgxMaskModule } from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatChipsModule } from '@angular/material/chips';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FaqComponent } from './components/faq/faq.component';
import { NgxTranslateModule } from './translate/ngx-translate.module';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from './translate/language.model';
//import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { AdminComponent } from './components/admin/admin.component';


const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


// Function Declarations
export function minlengthValidationMessage(err, field) {
  return `Should have atleast ${field.templateOptions && field.templateOptions.minLength
    } characters`;
}

export function maxlengthValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions && field.templateOptions.maxLength
    } characters`;
}

export function minValidationMessage(err, field) {
  return `This value should be more than ${field.templateOptions && field.templateOptions.min
    }`;
}

export function maxValidationMessage(err, field) {
  return `This value should be less than ${field.templateOptions && field.templateOptions.max
    }`;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(B2CAuthConfig.msalConfig);
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    return translate.use(Languages.Spanish.code).toPromise()
            .then( _ => translate.use(Languages.English.code));
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
 export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
 }

//  ****************************************************************************************************************************

@NgModule({
  declarations: [
    AppComponent,
    B2cLoginComponent,
    GuardedComponent,
    LoginComponent,
    LoginFailedComponent,
    DateDtoPipe,
    StringSpacerPipe,
    NegativeParenthesesPipe,
    UppercasePipe,
    HrefToRouterLinkDirective,
    NotAuthorizedComponent,
    ApplicationSettingComponent,
    ApplicationSettingsComponent,
    CheckpointsComponent,
    RoleComponent,
    RolesComponent,
    UserComponent,
    UsersComponent,
    ProfileComponent,
    ErrorLogComponent,
    ErrorLogsComponent,
    SpkSearchGridComponent,
    MenuComponent,
    HeaderComponent,
    FavoritesComponent,
    FaqComponent,
    AdminComponent,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    DndModule.forRoot(),
    MsalModule,
    NgxMaskModule.forRoot(),
    MatSlideToggleModule,
    MatTooltipModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    DashboardModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatSortModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    NgxTranslateModule,
    MatSortModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    ActionLinksPanelService,
    BrowserNavigateService,
    BusyService,
    CanDeactivateGuard,
    CanActivateGuard,
    ClientSessionService,
    ExecuteCommandService,
    PageContainerService,
    ReallyClickService,
    ValidationResultsService,
    ApiService,
    UnsavedChangesDialogService,
    NotificationService,
    SearchGridService,
    TabService,
    UserFileService,
    DateDtoPipe,
    PercentPipe,
    SearchGridCustomFilterService,
    JsonPipe,
    { provide: ErrorHandler, useClass: GlobalExceptionHandler },
    { provide: Notifier, useClass: ToastrNotifier },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
