import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '..';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContentMenuComponent } from './content-menu/content-menu.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DashboardService } from './services/dashboard.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from '../../app-routing.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    DashboardComponent,
    ContentMenuComponent,
    AlertsComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatSlideToggleModule,
    AppRoutingModule,
    TranslateModule.forChild({
      extend: true
    }),
  ],
  exports: [
    DashboardComponent,
    MatMenuModule,
    MatTableModule,
    MatIconModule
  ],
  providers: [
    //DashboardService
  ]
})
export class DashboardModule { }
