import { Injectable } from "@angular/core";

import { AppRole, UserInfoDto, ValueTextDto } from '../data/CommandServiceDtos.generated';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ClientSessionService {

  private readonly userInfoLocalStorageKey = 'userInfo';
  private emptyUserInfo: UserInfoDto = {
    Id: 0,
    UserName: "",
    FirstName: "",
    LastName: "",
    Roles: [],
    EmailAddress: "",
    LogCommands: false,
    Checkpoints: []
  }

  userInfoChanges$: BehaviorSubject<UserInfoDto> = new BehaviorSubject<UserInfoDto>(this.userInfo);

  constructor() { }

  get userInfo(): UserInfoDto {
    var result = this.emptyUserInfo;
    var jsonData = window.localStorage.getItem(this.userInfoLocalStorageKey);
    if (jsonData != null) {
      result = JSON.parse(jsonData);
    }

    return result;
  }

  set userInfo(value: UserInfoDto) {
    console.log("BAS Client Session Service - Setting User Info in LocalStorage");
    var jsonData = JSON.stringify(value);
    window.localStorage.setItem(this.userInfoLocalStorageKey, jsonData);
  }

  loginUser(userInfoDto: UserInfoDto) {
    this.userInfo = userInfoDto;
    this.userInfoChanges$.next(this.userInfo);
  }

  get loggedIn() {
    var result = this.userInfo.Id > 0;
    return result;
  }

  public reset() {
    window.localStorage.removeItem(this.userInfoLocalStorageKey);
    this.userInfoChanges$.next(this.userInfo);
  }

  get userName(): string {
    if (this.userInfo)
      return this.userInfo.UserName;
    else
      return "";
  }

  get userId(): number {
    if (this.userInfo)
      return this.userInfo.Id;
    else
      return 0;
  }

  get roles(): ValueTextDto[] {
    if (this.userInfo)
      return this.userInfo.Roles;
    else
      return [];
  }

  get isAdmin(): boolean {
    var result = this.isInRoleId(AppRole.Admin);
    return result;
  }

  public isInRoleName(role: string): boolean {
    var result = this.roles.filter((item) => { return item.Text == role }).length > 0;
    return result;
  }

  public isInRoleId(roleId: number): boolean {
    var result = this.roles.filter((item) => { return item.Value == roleId }).length > 0;
    return result;
  }

  get firstName(): string {
    if (this.userInfo)
      return this.userInfo.FirstName;
    else
      return "";
  }

  get lastName(): string {
    if (this.userInfo)
      return this.userInfo.LastName;
    else
      return "";
  }

  checkpoint(name: string): boolean {
    let result: boolean = false;
    let checkpointExists: boolean = false;
    for (let i = 0; i < this.userInfo.Checkpoints.length; i++) {
      const checkpoint = this.userInfo.Checkpoints[i];
      if (checkpoint.Name === name) {
        checkpointExists = true;
        result = checkpoint.HasAccess;
        break;
      }
    }
    if (!checkpointExists)
      result = true;
    return result;
  }
}
