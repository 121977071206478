import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppRoutes } from '../../app-route.model';
import { BookmarkDocumentCommandRequest } from '../../data/CommandServiceDtos.generated';
import { PageContainerService } from '../../services';
import { BreadcrumbService } from '../breadcrumb/breadcrumb.service';
import { DashboardService } from '../dashboard/services/dashboard.service';

@Component({
  selector: 'favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit, OnDestroy {
  tableData: any = {};
  subMenuOption: string[] = [];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'date', 'category', 'downloads'];
  dataSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dashBoardSrv: DashboardService,
    private breadcrumbService: BreadcrumbService,
    private pcs: PageContainerService, 
    private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit() {
    this.breadcrumbService.setBreadcrumb([
      AppRoutes.FAVORITES
    ]);
  }

  ngOnDestroy() {
    this.breadcrumbService.clear();
  }

  setFileIconType(fileType: string): string {
    return fileType;
  }

  downloadFile(data: any) {
    window.open(data, "_blank")
  }

  async setFavoriteState(doc): Promise<any> {

    const payload: BookmarkDocumentCommandRequest = new BookmarkDocumentCommandRequest();
    doc.data.UserFavorite = !doc.userFavorite;

    payload.BookmarkedDocuments = [doc.data];

    await this.pcs.apiService.BookmarkDocument(payload).then(response => {
      doc.userFavorite = !doc.userFavorite
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
