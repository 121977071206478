<div class="admin-component">
  <div class="survey-container">
    <div class="survey-header d-flex align-items-center mb-3">
      <h4 class="mb-0">Survey Link</h4>
      <button class="btn btn-primary px-4 ml-5" (click)="saveFeedbackSurveyLink()">
        <i class="fa fa-save"></i>  Save
      </button>
    </div>
    
    <div class="survey-content">
      <div class="survey-link mr-5">
        <input #surveyURL type="text" [(ngModel)]="feedbackSurveyInfo.FeedbackSurveyLinkURL"/>
      </div>
    </div>
  </div>

  <div class="faq-container">
    <div class="faq-header d-flex align-items-center mb-3">
      <h4 class="mb-0">Frequently Asked Questions</h4>
      <div *ngIf="allowFaqAdminEdit">
        <button class="btn btn-primary px-4 ml-5" (click)="addNewFAQ()" [disabled]="newMode || editMode">
          <i class="fa fa-plus"></i>  Create New FAQ Item
        </button>
      </div>
    </div>

    <div class="faq-list">
      <div class="faq-item" *ngFor="let faq  of faqList; let odd=odd;" [class.odd]="odd">
        <div class="qa-controls" *ngIf="allowFaqAdminEdit">
          <button mat-icon-button (click)="openDeleteFaqConfirmationModal(faq)" *ngIf="!faq.editing;" [disabled]="newMode">
            <mat-icon style="color: red" aria-label="icon-button" matTooltip="Delete FAQ">delete_forever</mat-icon>
          </button>
          <button mat-icon-button (click)="setEditMode(faq)" *ngIf="!faq.editing;" [disabled]="newMode">
            <mat-icon style="color: green" aria-label="icon-button" matTooltip="Edit FAQ">create</mat-icon>
          </button>
          <button mat-icon-button (click)="setEditMode(faq)" *ngIf="faq.editing;">
            <mat-icon style="color: red" aria-label="icon-button" matTooltip="Cancel Edit">cancel</mat-icon>
          </button>
          <button mat-icon-button (click)="saveFAQ(faq)" *ngIf="faq.editing;">
            <mat-icon style="color: darkblue" aria-label="icon-button" matTooltip="Save FAQ">save</mat-icon>
          </button>
        </div>
        <div class="title-text">
          <div>Q.</div> <span> {{setDate(faq.ValidFrom)}}</span>
        </div>
        <div *ngIf="!faq.editing; else editQuestion">{{faq.Question}}</div><ng-template #editQuestion> <textarea style="width: 100%" [(ngModel)]="faq.Question">{{faq.Question}}</textarea></ng-template>
        <div class="title-text">A.</div>
        <div *ngIf="!faq.editing; else editAnswer">{{faq.Answer}}</div><ng-template #editAnswer> <textarea style="width: 100%; display: inline-block;" [(ngModel)]="faq.Answer">{{faq.Answer}}</textarea></ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteFaqConfirmationModal let-modal>
  <div class="modal-header">
    <h5 class="mx-2 my-0">Delete FAQ</h5>
    <a class="close pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body mx-2">
    You are about to delete the FAQ: 
    "{{ faqToDelete.Question }}".<br/>
    Please confirm.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="deleteFAQ()">Delete</button>
    <button type="button" class="btn btn-danger" (click)="closeModal()">Cancel</button>
  </div>
</ng-template>
