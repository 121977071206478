export class Language {
  code: string;
  name: string;
}

export class Languages {
  static English: Language = { code: "en", name: "English"};
  static Spanish: Language = { code: "es", name: "Spanish"};

  static getByCode(languageCode: string): Language {
    const language = Object.entries(Languages).find(e => e[1].code == languageCode);
    return language ? language[1] : null;
  }
}
