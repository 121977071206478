import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserAlertDto } from '../../../data/CommandServiceDtos.generated';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  @Input() info: UserAlertDto;
  @Output() close: EventEmitter<UserAlertDto> = new EventEmitter<UserAlertDto>();

  public alertMessageText: string = "";
  public alertGeneratedDate: string = "";

  constructor() { }

  ngOnInit(): void {
    this.alertMessageText = this.info.AlertMessage;
    this.alertGeneratedDate = this.info.AlertDateReadable;
  }

  closeAlert(): void {
    this.close.emit(this.info);
  }

}
