<div class="favorites">

  <main class="content-area">
    <div class="table-content">
      <table mat-table [dataSource]="(dashBoardSrv.favoriteData$ | async)" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z0" style="width: 100%; color: #696b6d">

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"></th>
          <td width="40px" style="width: 40px" mat-cell *matCellDef="let element">
            <i class="material-icons"
               style="cursor: pointer;"
               [style.color]="element.userFavorite ? 'gold' :'#e5e5e5'"
               (click)="setFavoriteState(element)">star</i>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
            {{ 'TYPE' | translate }}
          </th>
          <td width="40px" mat-cell *matCellDef="let element">
            <mat-icon [svgIcon]="setFileIconType(element.type)"></mat-icon>
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th width="200px" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
            {{ 'NAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            {{ 'DESCRIPTION' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" style="max-width: 20%"> {{element.description}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="date">
          <th width="120px" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            {{ 'DATE' | translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            {{ 'CATEGORY' | translate }}
          </th>
          <td class="truncate" style="max-width: 200px" mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="downloads">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
            {{ 'DOWNLOADS' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex">
              <button mat-flat-button class="download-chip" style="margin-right: 10px" (click)="downloadFile(element.englishVersionURL)" [disabled]="element.englishVersionURL.length === 0">
                <i class="material-icons">download</i>
                <span>ENG</span>
              </button>
              <button mat-flat-button class="download-chip" style="margin-right: 10px" (click)="downloadFile(element.spanishVersionURL)" [disabled]="element.spanishVersionURL.length === 0">
                <i class="material-icons">download</i>
                <span>ESP</span>
              </button>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </main>
</div>
