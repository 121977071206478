import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, Params } from '../ng';
import * as $ from 'jquery';

@Injectable()
export class TabService {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  handleTabAndModalQueryParams(queryParams: Params): any {
    if (queryParams['modal']) {
      var modal = queryParams['modal'];
      setTimeout(() => {
        $(`#${modal}`).modal('show');
      }, 600);
    }
    let tab: string;
    if (queryParams['tab'])
      tab = queryParams['tab'];
    this.findAndSelectTab(tab, queryParams);
  }

  findAndSelectTab(tab: string, queryParams: Params = null) {
    let selector = tab ? '#' + tab : ':first';
    var tabSelection = setInterval(() => {
      var $tabElement = $('.spk-tab' + selector);
      if ($tabElement[0]) {
        $tabElement[0].click();
        this.updateTab($tabElement[0].id);
        clearInterval(tabSelection);
        if (queryParams != null && queryParams['subTab']) {
          this.findAndSelectSubTab(queryParams['subTab'])
        }
      }
    }, 50);
  }

  updateTab(tab: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          tab: tab,
          modal: null
        },
        queryParamsHandling: "merge",
        replaceUrl: true
      });
    this.showTab(tab);
  }

  private showTab(tab: string) {
    $('.spk-tab').removeClass('active');
    $('.spk-tab#' + tab).addClass('active');
    $('.spk-tab-pane').hide().removeClass('show');
    $('.spk-tab-pane#' + tab + 'content').show().addClass('show');
  }

  findAndSelectSubTab(tab: string) {
    let selector = tab ? '#' + tab : ':first';
    var tabSelection = setInterval(() => {
      var $tabElement = $('.spk-sub-tab' + selector);
      if ($tabElement[0]) {
        //$tabElement[0].click();
        this.updateSubTab($tabElement[0].id);
        clearInterval(tabSelection);
      }
    }, 50);
  }

  updateSubTab(tab: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          subTab: tab,
          modal: null
        },
        queryParamsHandling: "merge",
        replaceUrl: true
      });
    this.showSubTab(tab);
  }

  private showSubTab(tab: string) {
    $('.spk-sub-tab').removeClass('active');
    $('.spk-sub-tab#' + tab).addClass('active');
    $('.spk-sub-tab-pane').hide().removeClass('show');
    $('.spk-sub-tab-pane#' + tab + 'content').show().addClass('show');
  }
}
