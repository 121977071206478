import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "../../../environments/environment";

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Collab_BAS_SignUp_SignIn",
    // editProfile: "B2C_1_Collab_BAS_EditProfile"
  },
  authorities: {
    signUpSignIn: {
      authority: environment.authorityURI + "/" + environment.signUpSignInUserFlow,
    },
    // editProfile: {
    //   authority: environment.authorityURI + "/" + environment.editProfileUserFlow,
    // }
  },
  authorityDomain: environment.authorityDomain
};

export const protectedResources = {
  collabForChildrenAPI: {
    endpoint: environment.redirectUri,
    scopes: [environment.scopes],
  },
};

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["collab-bas.Read", "collab-bas.Write"],
    },
    loginFailedRoute: "./login-failed",
  };
}

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
 export const silentRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'example@collabforchildren.org',
};
