import * as Data from '../../data';
import { PageContainerService } from '../../services';
import { ControllerBase } from './controller-base';

export class ControllerWithCommandServiceBase extends ControllerBase {

  constructor(
    protected pcs: PageContainerService
  ) {
    super();
  }

  protected executeCommand(commandName: String, payload: any, onCommandSucceeded?: (commandName: String, response: any) => void, useBusyIndicator?: boolean): Promise<any> {
    var promise = this.pcs.executeCommandService.executeCommand(commandName, payload, useBusyIndicator);
    promise.then(response => onCommandSucceeded(commandName, response));
    return promise;
  }
}
