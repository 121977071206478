import { ViewEncapsulation, OnInit, Component, OnDestroy, Inject } from "@angular/core";
import { PageContainerService, BrowserNavigateService } from "./services";
import { ActivatedRoute, NavigationEnd, Params } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { SignalRService } from "./services/signal-r.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {
  canB2CLogin: boolean = true;
  previousUrl = "";
  private readonly _destroying$ = new Subject<void>();

  iconRegistry: MatIconRegistry;
  sanitizer: DomSanitizer;


  constructor(
    private activatedRoute: ActivatedRoute,
    private pcs: PageContainerService,
    private platformLocation: PlatformLocation,
    private browserNavigateService: BrowserNavigateService,
    private signalRService: SignalRService,
    private msalBroadcastService: MsalBroadcastService,
    iconRegistration: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {


    this.iconRegistry = iconRegistration;
    this.sanitizer = domSanitizer;
    this.setIconRegistration();

    console.log("AppComponent Constructor START");

    this.platformLocation.onPopState(() => {
      this.browserNavigateService.navigatedBack = true;
    });
    pcs.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        //on navigation changed (in case navigation changing without running a command to clear out the notifications/validation results)
        this.browserNavigateService.reset();
        this.pcs.handleNavigationEnd();
        if (this.navigationIsPageChange(val.url)) {
          //do stuff that needs to happen on url change here

          // 9248 - enable header and login components because we've left User sign-up page
          if (this.previousUrl.includes("user-sign-up") === true) {
            this.canB2CLogin = true;
          }
        }
        this.previousUrl = val.url;
      }
    });

    console.log("AppComponent Constructor END");
  }

  navigationIsPageChange(newUrl: string): boolean {
    const newUrlWithoutQueryParams = this.removeQueryParams(newUrl);
    const previousUrlWithoutQueryParams = this.removeQueryParams(
      this.previousUrl
    );
    if (
      newUrlWithoutQueryParams.toLowerCase() !==
      previousUrlWithoutQueryParams.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  }

  removeQueryParams(url: string): string {
    const indexOfQueryParams = url.indexOf("?");
    let urlWithoutQueryParams = url;
    if (indexOfQueryParams > -1) {
      urlWithoutQueryParams = url.substring(0, indexOfQueryParams);
    }
    urlWithoutQueryParams = urlWithoutQueryParams.replace(/[0-9]/g, "");
    return urlWithoutQueryParams;
  }

  ngOnInit() {

    console.log("AppComponent ngOnInit START");

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
    )
      .subscribe(() => {
        if (!this.pcs.clientSessionService.loggedIn) {
          this.pcs.clientSessionService.reset();
        }

        this.signalRService.startConnection();
        this.signalRService.addAllListeners();

        this.activatedRoute.url.subscribe(() => {
          if (window.location.pathname === "/user-sign-up") {
            //9248 - we're in the user-sign up page
            // we don't need B2Login functionality
            this.canB2CLogin = false;
            // alert("in app component, can B2C Login: " + this.canB2CLogin);
          }
        });
      })
    
    console.log("AppComponent ngOnInit END");
  }

  get isActionLinksMenuVisible(): boolean {
    return this.pcs.actionLinksPanelService.hasActionLinks;
  }

  get isBusy(): boolean {
    const result = this.pcs.busyService.isBusy;
    return result;
  }

  actionLinkClass(): string {
    const result = this.isActionLinksMenuVisible
      ? "with-actionLinksBar"
      : "without-actionLinksBar";
    return result;
  }

  toggleMenu() { }

  setIconRegistration() {
    this.iconRegistry.addSvgIcon('menu-attendance', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-attendance.svg'));
    this.iconRegistry.addSvgIcon('menu-coe', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-center-of-excellence.svg'));
    this.iconRegistry.addSvgIcon('menu-faq', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-faq.svg'));
    this.iconRegistry.addSvgIcon('menu-fav', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-favorites.svg'));
    this.iconRegistry.addSvgIcon('menu-files', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-files.svg'));
    this.iconRegistry.addSvgIcon('menu-logo', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-logo.svg'));
    this.iconRegistry.addSvgIcon('menu-students', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-students.svg'));
    this.iconRegistry.addSvgIcon('menu-teachers', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-teachers.svg'));
    this.iconRegistry.addSvgIcon('menu-users', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/menu-icon-users.svg'));
    this.iconRegistry.addSvgIcon('privacy-policy', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/privacy-policy.svg'));

    this.iconRegistry.addSvgIcon('filter-icon-left', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-left.svg'));
    this.iconRegistry.addSvgIcon('filter-icon-right', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-right.svg'));
    this.iconRegistry.addSvgIcon('filter-1', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-1.svg'));
    this.iconRegistry.addSvgIcon('filter-2', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-2.svg'));
    this.iconRegistry.addSvgIcon('filter-3', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-3.svg'));
    this.iconRegistry.addSvgIcon('filter-4', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-4.svg'));
    this.iconRegistry.addSvgIcon('filter-5', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-5.svg'));
    this.iconRegistry.addSvgIcon('filter-6', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-6.svg'));
    this.iconRegistry.addSvgIcon('filter-7', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-7.svg'));
    this.iconRegistry.addSvgIcon('filter-8', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/filter-icon-8.svg'));

    this.iconRegistry.addSvgIcon('doc', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/icon-doc.svg'));
    this.iconRegistry.addSvgIcon('word', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/icon-doc.svg'));
    this.iconRegistry.addSvgIcon('excel', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/icon-excel.svg'));
    this.iconRegistry.addSvgIcon('pdf', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/icon-pdf.svg'));
    this.iconRegistry.addSvgIcon('ppt', this.sanitizer.bypassSecurityTrustResourceUrl('app/assets/images/icon-ppt.svg'));
  }
}
