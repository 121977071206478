// **** Auto-generated by Sparkhound.CodeGenerator. ****
import { Injectable } from '@angular/core';
import { ExecuteCommandService } from '../services/execute-command.service';
import { CommandResponseTyped } from './framework/commandResponse';
import * as Web from './CommandServiceDtos.generated';
import * as Search from './framework/searchObjects';
import * as _ from 'lodash';


@Injectable()
export class ApiService {

    public constructor(
        private executeCommandService: ExecuteCommandService
    ) {
    
    }

    public async Search<T>(request: Search.SearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Search.SearchCommandResponse<T>> {
        let response: CommandResponseTyped<Search.SearchCommandResponse<T>> = await this.executeCommandService.executeCommand("Search", request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Admin(request: Web.AdminCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AdminCommandResponse> {
        let response: CommandResponseTyped<Web.AdminCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.Admin, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async AdminGetFeedbackSurveyLink(request: Web.AdminGetFeedbackSurveyLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AdminGetFeedbackSurveyLinkCommandResponse> {
        let response: CommandResponseTyped<Web.AdminGetFeedbackSurveyLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.AdminGetFeedbackSurveyLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async AdminSaveFeedbackSurveyLink(request: Web.AdminSaveFeedbackSurveyLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AdminSaveFeedbackSurveyLinkCommandResponse> {
        let response: CommandResponseTyped<Web.AdminSaveFeedbackSurveyLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.AdminSaveFeedbackSurveyLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async AdminSaveFrequentQuestionList(request: Web.AdminSaveFrequentQuestionListCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AdminSaveFrequentQuestionListCommandResponse> {
        let response: CommandResponseTyped<Web.AdminSaveFrequentQuestionListCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.AdminSaveFrequentQuestionList, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async AuditUserDocumentUsage(request: Web.AuditUserDocumentUsageCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.AuditUserDocumentUsageCommandResponse> {
        let response: CommandResponseTyped<Web.AuditUserDocumentUsageCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.AuditUserDocumentUsage, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async BookmarkDocument(request: Web.BookmarkDocumentCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.BookmarkDocumentCommandResponse> {
        let response: CommandResponseTyped<Web.BookmarkDocumentCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.BookmarkDocument, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CreateLocalUserAccount(request: Web.CreateLocalUserAccountCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CreateLocalUserAccountCommandResponse> {
        let response: CommandResponseTyped<Web.CreateLocalUserAccountCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CreateLocalUserAccount, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async CreateTestDummyCommandQueue(request: Web.CreateTestDummyCommandQueueCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.CreateTestDummyCommandQueueCommandResponse> {
        let response: CommandResponseTyped<Web.CreateTestDummyCommandQueueCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.CreateTestDummyCommandQueue, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteCheckpoint(request: Web.DeleteCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteExample(request: Web.DeleteExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteFileLink(request: Web.DeleteFileLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteFileLinkCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteFileLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteFileLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteProgram(request: Web.DeleteProgramCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteProgramCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteProgramCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteProgram, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async DeleteRole(request: Web.DeleteRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.DeleteRoleCommandResponse> {
        let response: CommandResponseTyped<Web.DeleteRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.DeleteRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async EditFile(request: Web.EditFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.EditFileCommandResponse> {
        let response: CommandResponseTyped<Web.EditFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.EditFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecuteAllPendingQueues(request: Web.ExecuteAllPendingQueuesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecuteAllPendingQueuesCommandResponse> {
        let response: CommandResponseTyped<Web.ExecuteAllPendingQueuesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecuteAllPendingQueues, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecuteNextQueueEntry(request: Web.ExecuteNextQueueEntryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecuteNextQueueEntryCommandResponse> {
        let response: CommandResponseTyped<Web.ExecuteNextQueueEntryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecuteNextQueueEntry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExecutePendingQueueEntries(request: Web.ExecutePendingQueueEntriesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExecutePendingQueueEntriesCommandResponse> {
        let response: CommandResponseTyped<Web.ExecutePendingQueueEntriesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExecutePendingQueueEntries, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ExportCheckpointsToCSV(request: Web.ExportCheckpointsToCSVCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ExportCheckpointsToCSVCommandResponse> {
        let response: CommandResponseTyped<Web.ExportCheckpointsToCSVCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ExportCheckpointsToCSV, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GenerateAttendanceSheet(request: Web.GenerateAttendanceSheetCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GenerateAttendanceSheetCommandResponse> {
        let response: CommandResponseTyped<Web.GenerateAttendanceSheetCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GenerateAttendanceSheet, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GenerateDailyDefaultAttendanceSheets(request: Web.GenerateDailyDefaultAttendanceSheetsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GenerateDailyDefaultAttendanceSheetsCommandResponse> {
        let response: CommandResponseTyped<Web.GenerateDailyDefaultAttendanceSheetsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GenerateDailyDefaultAttendanceSheets, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAcceleratorDocumentClassificationList(request: Web.GetAcceleratorDocumentClassificationListCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAcceleratorDocumentClassificationListCommandResponse> {
        let response: CommandResponseTyped<Web.GetAcceleratorDocumentClassificationListCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAcceleratorDocumentClassificationList, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAcceleratorDocumentList(request: Web.GetAcceleratorDocumentListCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAcceleratorDocumentListCommandResponse> {
        let response: CommandResponseTyped<Web.GetAcceleratorDocumentListCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAcceleratorDocumentList, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetApplicationSetting(request: Web.GetApplicationSettingCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetApplicationSettingCommandResponse> {
        let response: CommandResponseTyped<Web.GetApplicationSettingCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetApplicationSetting, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetAttendanceSheet(request: Web.GetAttendanceSheetCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetAttendanceSheetCommandResponse> {
        let response: CommandResponseTyped<Web.GetAttendanceSheetCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetAttendanceSheet, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCenter(request: Web.GetCenterCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCenterCommandResponse> {
        let response: CommandResponseTyped<Web.GetCenterCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCenter, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCenterStatus(request: Web.GetCenterStatusCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCenterStatusCommandResponse> {
        let response: CommandResponseTyped<Web.GetCenterStatusCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCenterStatus, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCheckpoint(request: Web.GetCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.GetCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetChild(request: Web.GetChildCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetChildCommandResponse> {
        let response: CommandResponseTyped<Web.GetChildCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetChild, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetClass(request: Web.GetClassCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetClassCommandResponse> {
        let response: CommandResponseTyped<Web.GetClassCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetClass, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCountry(request: Web.GetCountryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCountryCommandResponse> {
        let response: CommandResponseTyped<Web.GetCountryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCountry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetCurrentUserProfile(request: Web.GetCurrentUserProfileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetCurrentUserProfileCommandResponse> {
        let response: CommandResponseTyped<Web.GetCurrentUserProfileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetCurrentUserProfile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetDashboard(request: Web.GetDashboardCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetDashboardCommandResponse> {
        let response: CommandResponseTyped<Web.GetDashboardCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetDashboard, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetExample(request: Web.GetExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetExampleCommandResponse> {
        let response: CommandResponseTyped<Web.GetExampleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetException(request: Web.GetExceptionCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetExceptionCommandResponse> {
        let response: CommandResponseTyped<Web.GetExceptionCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetException, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFile(request: Web.GetFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFileCommandResponse> {
        let response: CommandResponseTyped<Web.GetFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetFrequentlyAskedQuestionList(request: Web.GetFrequentlyAskedQuestionListCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetFrequentlyAskedQuestionListCommandResponse> {
        let response: CommandResponseTyped<Web.GetFrequentlyAskedQuestionListCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetFrequentlyAskedQuestionList, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetGuardian(request: Web.GetGuardianCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetGuardianCommandResponse> {
        let response: CommandResponseTyped<Web.GetGuardianCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetGuardian, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetMenu(request: Web.GetMenuCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetMenuCommandResponse> {
        let response: CommandResponseTyped<Web.GetMenuCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetMenu, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetMyProfile(request: Web.GetMyProfileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetMyProfileCommandResponse> {
        let response: CommandResponseTyped<Web.GetMyProfileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetMyProfile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetProgram(request: Web.GetProgramCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetProgramCommandResponse> {
        let response: CommandResponseTyped<Web.GetProgramCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetProgram, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetQueueDetail(request: Web.GetQueueDetailCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetQueueDetailCommandResponse> {
        let response: CommandResponseTyped<Web.GetQueueDetailCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetQueueDetail, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRecentUserFiles(request: Web.GetRecentUserFilesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRecentUserFilesCommandResponse> {
        let response: CommandResponseTyped<Web.GetRecentUserFilesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRecentUserFiles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetReleaseNumber(request: Web.GetReleaseNumberCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetReleaseNumberCommandResponse> {
        let response: CommandResponseTyped<Web.GetReleaseNumberCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetReleaseNumber, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRole(request: Web.GetRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRoleCommandResponse> {
        let response: CommandResponseTyped<Web.GetRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetRoles(request: Web.GetRolesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetRolesCommandResponse> {
        let response: CommandResponseTyped<Web.GetRolesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetRoles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetTeacher(request: Web.GetTeacherCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetTeacherCommandResponse> {
        let response: CommandResponseTyped<Web.GetTeacherCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetTeacher, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserAcceleratorAlerts(request: Web.GetUserAcceleratorAlertsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserAcceleratorAlertsCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserAcceleratorAlertsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserAcceleratorAlerts, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUser(request: Web.GetUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async GetUserWidgetSelections(request: Web.GetUserWidgetSelectionsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.GetUserWidgetSelectionsCommandResponse> {
        let response: CommandResponseTyped<Web.GetUserWidgetSelectionsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.GetUserWidgetSelections, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ImportCheckpointsFromCSV(request: Web.ImportCheckpointsFromCSVCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ImportCheckpointsFromCSVCommandResponse> {
        let response: CommandResponseTyped<Web.ImportCheckpointsFromCSVCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ImportCheckpointsFromCSV, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async IsUserAuthenticated(request: Web.IsUserAuthenticatedCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.IsUserAuthenticatedCommandResponse> {
        let response: CommandResponseTyped<Web.IsUserAuthenticatedCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.IsUserAuthenticated, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Login(request: Web.LoginCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.LoginCommandResponse> {
        let response: CommandResponseTyped<Web.LoginCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.Login, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async Logout(request: Web.LogoutCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.Logout, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async LogTypeScriptError(request: Web.LogTypeScriptErrorCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<object> {
        let response: CommandResponseTyped<object> = await this.executeCommandService.executeCommand(Web.CommandNames.LogTypeScriptError, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async OverdueAttendanceSheetCheck(request: Web.OverdueAttendanceSheetCheckCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.OverdueAttendanceSheetCheckCommandResponse> {
        let response: CommandResponseTyped<Web.OverdueAttendanceSheetCheckCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.OverdueAttendanceSheetCheck, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async PdfSearch(request: Web.PdfSearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.PdfSearchCommandResponse> {
        let response: CommandResponseTyped<Web.PdfSearchCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.PdfSearch, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async ProcessEmailQueue(request: Web.ProcessEmailQueueCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.ProcessEmailQueueCommandResponse> {
        let response: CommandResponseTyped<Web.ProcessEmailQueueCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.ProcessEmailQueue, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async QueueUserFile(request: Web.QueueUserFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.QueueUserFileCommandResponse> {
        let response: CommandResponseTyped<Web.QueueUserFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.QueueUserFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async RemoveUserFavoriteDocument(request: Web.RemoveUserFavoriteDocumentCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.RemoveUserFavoriteDocumentCommandResponse> {
        let response: CommandResponseTyped<Web.RemoveUserFavoriteDocumentCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.RemoveUserFavoriteDocument, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveApplicationSetting(request: Web.SaveApplicationSettingCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveApplicationSettingCommandResponse> {
        let response: CommandResponseTyped<Web.SaveApplicationSettingCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveApplicationSetting, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveAttendanceSheet(request: Web.SaveAttendanceSheetCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveAttendanceSheetCommandResponse> {
        let response: CommandResponseTyped<Web.SaveAttendanceSheetCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveAttendanceSheet, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCenter(request: Web.SaveCenterCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCenterCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCenterCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCenter, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCheckpoint(request: Web.SaveCheckpointCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCheckpointCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCheckpointCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCheckpoint, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveChild(request: Web.SaveChildCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveChildCommandResponse> {
        let response: CommandResponseTyped<Web.SaveChildCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveChild, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveClass(request: Web.SaveClassCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveClassCommandResponse> {
        let response: CommandResponseTyped<Web.SaveClassCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveClass, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveCountry(request: Web.SaveCountryCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveCountryCommandResponse> {
        let response: CommandResponseTyped<Web.SaveCountryCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveCountry, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveExample(request: Web.SaveExampleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveExampleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveExampleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveExample, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFile(request: Web.SaveFileCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFileCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFileCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFile, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveFileLink(request: Web.SaveFileLinkCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveFileLinkCommandResponse> {
        let response: CommandResponseTyped<Web.SaveFileLinkCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveFileLink, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveGuardian(request: Web.SaveGuardianCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveGuardianCommandResponse> {
        let response: CommandResponseTyped<Web.SaveGuardianCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveGuardian, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveProgram(request: Web.SaveProgramCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveProgramCommandResponse> {
        let response: CommandResponseTyped<Web.SaveProgramCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveProgram, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveRole(request: Web.SaveRoleCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveRoleCommandResponse> {
        let response: CommandResponseTyped<Web.SaveRoleCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveRole, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveTeacher(request: Web.SaveTeacherCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveTeacherCommandResponse> {
        let response: CommandResponseTyped<Web.SaveTeacherCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveTeacher, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUser(request: Web.SaveUserCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUser, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserSignUp(request: Web.SaveUserSignUpCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserSignUpCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserSignUpCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserSignUp, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserWidgetOrder(request: Web.SaveUserWidgetOrderCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserWidgetOrderCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserWidgetOrderCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserWidgetOrder, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SaveUserWidgetSelections(request: Web.SaveUserWidgetSelectionsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SaveUserWidgetSelectionsCommandResponse> {
        let response: CommandResponseTyped<Web.SaveUserWidgetSelectionsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SaveUserWidgetSelections, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchAttendance(request: Web.SearchAttendanceCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchAttendanceCommandResponse> {
        let response: CommandResponseTyped<Web.SearchAttendanceCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchAttendance, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchCenter(request: Web.SearchCenterCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchCenterCommandResponse> {
        let response: CommandResponseTyped<Web.SearchCenterCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchCenter, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchChild(request: Web.SearchChildCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchChildCommandResponse> {
        let response: CommandResponseTyped<Web.SearchChildCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchChild, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchClass(request: Web.SearchClassCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchClassCommandResponse> {
        let response: CommandResponseTyped<Web.SearchClassCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchClass, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchObjects(request: Web.SearchObjectsCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchObjectsCommandResponse> {
        let response: CommandResponseTyped<Web.SearchObjectsCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchObjects, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchTeacher(request: Web.SearchTeacherCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchTeacherCommandResponse> {
        let response: CommandResponseTyped<Web.SearchTeacherCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchTeacher, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SearchUserFiles(request: Web.SearchUserFilesCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SearchUserFilesCommandResponse> {
        let response: CommandResponseTyped<Web.SearchUserFilesCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SearchUserFiles, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async SetUserAcceleratorAlertReadStatus(request: Web.SetUserAcceleratorAlertReadStatusCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.SetUserAcceleratorAlertReadStatusCommandResponse> {
        let response: CommandResponseTyped<Web.SetUserAcceleratorAlertReadStatusCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.SetUserAcceleratorAlertReadStatus, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async TestDummy(request: Web.TestDummyCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.TestDummyCommandResponse> {
        let response: CommandResponseTyped<Web.TestDummyCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.TestDummy, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async TriggerServerException(request: Web.TriggerServerExceptionCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.TriggerServerExceptionCommandResponse> {
        let response: CommandResponseTyped<Web.TriggerServerExceptionCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.TriggerServerException, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }

    public async UnusedSecurityCheckpointSearch(request: Web.UnusedSecurityCheckpointSearchCommandRequest, useBusyIndicator?: boolean, showNotificationOnSuccess?: boolean, removePersistentNotifications?: boolean): Promise<Web.UnusedSecurityCheckpointSearchCommandResponse> {
        let response: CommandResponseTyped<Web.UnusedSecurityCheckpointSearchCommandResponse> = await this.executeCommandService.executeCommand(Web.CommandNames.UnusedSecurityCheckpointSearch, request, useBusyIndicator, showNotificationOnSuccess, removePersistentNotifications);
        let result = response.Payload;
        return result;
    }
}
