import { Component, Input } from "@angular/core";
import { OnInit, ActivatedRoute, Params } from "../../../ng";
import { MsalService } from "@azure/msal-angular";

import { ControllerForViewBase } from "../../framework/controller-for-view-base";
import { PageContainerService } from "../../../services";
import {
  LoginCommandRequest,
  AuthenticationType,
  AuthenticationTypeDisplayMap
} from "../../../data/CommandServiceDtos.generated";
import { LoginService } from "./login.service";

const config = require("../../../../app-config.json");

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends ControllerForViewBase implements OnInit {
  formModel: LoginCommandRequest = new LoginCommandRequest();
  isB2C: boolean = false;
  loggedIn: boolean = false;
  redirectRoute: string;
  screenMessage: string = "Let's get logged in....";
  isAuthenticated: boolean = false;

  constructor(
    pcs: PageContainerService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private msalService: MsalService
  ) {
    super(pcs);
    this.redirectRoute =
      this.activatedRoute.snapshot.queryParams["redirectRoute"];
  }

  async ngOnInit() {
    console.log("In LoginComponent - ngOniInit(),   ");

    // listen for login event from HeaderComponent
    // process user login details on server
    this.loginService.userLoginChangeEmitted$.subscribe((userLogin) => {
      if (userLogin !== null) {
        console.log("In LoginComponent - ngOniInit(), obtained userLogin.  ");

        // 8187 - get token from header
        this.formModel.ServiceToken = userLogin.idToken;
        this.formModel.Username = userLogin.username;

        // check authentication of user in BOS Db
        this.pcs.apiService
          .Login(this.formModel)
          .then((loginCommandResponse) => {
            if (loginCommandResponse.IsAuthenticated) {
              this.pcs.notificationService.info("You are now logged in.");
              this.pcs.clientSessionService.loginUser(
                loginCommandResponse.UserInfo
              );
              this.isAuthenticated = true;
              
              this.navigateAfterLogin();
            } else {
              this.pcs.notificationService.danger(
                loginCommandResponse.FailedAuthMessage
              );

              this.isAuthenticated = false;
            }
          });
      }
    });

    this.loginService.loginMessageChangeEmitted$.subscribe((message) => {
      if (message !== null) {
        this.screenMessage = message;
      }
    });

    //await this.handleAuthenticationScheme();
  }

  async handleAuthenticationScheme() {
    let authSchemeString = config.security.authenticationType;
    switch (authSchemeString) {
      case AuthenticationTypeDisplayMap[
        AuthenticationType.LoginActiveDirectory
      ]:
      case AuthenticationTypeDisplayMap[AuthenticationType.Database]:
        await this.handleLoginFormAuthenticationTypes();

        break;
      case AuthenticationTypeDisplayMap[
        AuthenticationType.AzureActiveDirectory
      ]:
      case AuthenticationTypeDisplayMap[AuthenticationType.AutoActiveDirectory]:
        await this.handleAutoLoginAuthenticationTypes();
        break;
      default:
    }
  }

  async handleLoginFormAuthenticationTypes() {
    let response = await this.pcs.apiService.IsUserAuthenticated(
      {},
      true,
      false
    );
    if (response.IsAuthenticated) {
      this.pcs.clientSessionService.loginUser(response.UserInfo);
      this.navigateAfterLogin();
    } else {
      this.pcs.clientSessionService.reset();
    }
  }

  async handleAutoLoginAuthenticationTypes() {
    await this.login().catch((error) => this.handleError(error));
  }

  handleError(error) {
    this.screenMessage = "Login failed!";

    this.msalService.logoutRedirect();
  }

  async login() { }

  isLoggedIn(): boolean {
    if (
      this.msalService.instance.getActiveAccount() !== null &&
      this.isAuthenticated
    ) {
      this.screenMessage = "";

      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.msalService.logoutRedirect();
  }

  /**
   * if we have an account show Username/profile
   * otherwise show "Login" link button
   */
  setLoginDisplay() {
    this.loggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }

  private navigateAfterLogin() {
    if (
      this.redirectRoute !== "" &&
      this.redirectRoute != null &&
      this.redirectRoute != "/login" &&
      this.redirectRoute != undefined
    ) {
      this.pcs.router.navigateByUrl(this.redirectRoute);
    } else {
      this.pcs.router.navigate(["/"]);
    }
  }

  get loginFormAuthType(): boolean {
    let result =
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.LoginActiveDirectory] ||
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.Database];

    return result;
  }

  get autoLoginFormAuthType(): boolean {
    let result =
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.AzureActiveDirectory] ||
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.AutoActiveDirectory] ||
      config.security.authenticationType ===
      AuthenticationTypeDisplayMap[AuthenticationType.B2C];

    return result;
  }
}
