import { Component, AfterViewInit } from '@angular/core';
import { ControllerForViewBase } from '../../controller-for-view-base';
import { ILink } from '../../../../data';
import { PageContainerService } from '../../../../services';
import { ApplicationSettingSearchRow } from '../../../../data/CommandServiceDtos.generated';

@Component({
  selector: 'application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent extends ControllerForViewBase implements AfterViewInit {
  ngAfterViewInit(): void {
  }

  leftLinks: ILink[];
  constructor(public pcs: PageContainerService) {
    super(pcs);
    let links: ILink[] = [
      { text: '', action: (row) => this.openRow(row), title: 'Open', icon: 'folder-open', class: 'btn-primary' }
    ];
    this.leftLinks = links;

    this.pcs.actionLinksPanelService.addBackButton();
  }

  openRow(row: ApplicationSettingSearchRow): void {
    this.pcs.router.navigate(['/application-setting', row.Name]);
  }
}
