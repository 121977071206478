// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// SET THESE VALUES HERE:
var authDomain = "prodb2ccollabforchildren.b2clogin.com";
var onMicrosoftDomain = "prodb2ccollabforchildren.onmicrosoft.com";

var appURI = window.location.origin + "/";
var clientId = "c5422253-943d-4595-b8b8-ae47d03f709b";

var authorityURI = "https://" + authDomain + "/" + onMicrosoftDomain + "/";
var readScopeName = "collab-bas.Read";
var writeScopeName = "collab-bas.Write";

export const environment = {
  production: false,
  authorityDomain: authDomain,
  authorityURI: authorityURI,
  clientId: clientId,
  knownAuthorities: [authDomain],
  redirectUri: appURI + "login",
  signUpSignInUserFlow: "B2C_1_Collab_BAS_SignUp_SignIn",
  editProfileUserFlow: "B2C_1_Collab_BAS_EditProfile",
  interceptorReadResourceKey: "https://" + onMicrosoftDomain + "/" + clientId + "/" + readScopeName,
  interceptorReadResourceValue: readScopeName,
  interceptorWriteResourceKey: "https://" + onMicrosoftDomain + "/" + clientId + "/" + writeScopeName,
  interceptorWriteResourceValue: writeScopeName,
  uri: appURI,
  scopes: [
    "https://" +
    onMicrosoftDomain +
    "/" +
    clientId +
    "/collab-bas.Write",
    "https://" +
    onMicrosoftDomain +
    "/" +
    clientId +
    "/collab-bas.Read",
  ],
};
